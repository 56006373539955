import React from "react";
import ValidationError from "./ValidationError";

const Input = ({
  type,
  name,
  placeholder,
  label,
  value,
  onChange,
  onBlur,
  disabled = false,
  error = "",
  serverError = "",
  touched = null,
}) => {
  return (
    <div className="mt-2">
      <label
        htmlFor="name"
        className="block text-sm font-medium capitalize text-gray-700"
      >
        {label}
      </label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={`bg-gray-50 border ${
          (error && touched) || serverError
            ? "border-red-500"
            : "border-gray-200"
        } w-full rounded-md border placeholder:text-base bg-white border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-accent-500 focus:accent`}
        placeholder={placeholder}
        onBlur={onBlur}
      />
      <ValidationError
        error={error}
        serverError={serverError}
        touched={touched}
      />
    </div>
  );
};

export default Input;
