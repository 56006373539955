import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createSlug,
  formatAmountToINR,
  getAssetUriWithSize,
} from "../../utils/helper";
import { CHECKOUT_ROUTE, PRODUCT_DETAIL_ROUTE } from "../../utils/router";
import { deleteFromCart } from "../../redux/headerSlice";
import { MdShoppingCartCheckout } from "react-icons/md";
import { Button } from "@mui/material";
const Cart = ({ toggleCart }) => {
  const carts = useSelector((state) => state.headerRedux.carts);
  const dispatch = useDispatch();

  const calculateTotal = () => {
    let total = 0;
    for (const key in carts) {
      if (carts.hasOwnProperty(key)) {
        const item = carts[key];
        if (
          item &&
          item.variation &&
          item.variation.active_price &&
          item.variation.active_price.price
        ) {
          let productPrice = item.variation.active_price.price * item.quantity;
          if (
            item.variation.active_price &&
            item.variation.active_price.discount > 0
          ) {
            let discount =
              (productPrice * item.variation.active_price.discount) / 100;
            total += productPrice - discount;
          } else {
            total += productPrice;
          }
        }
      }
    }
    return total;
  };

  const handleDelete = (productId, variationId) => {
    dispatch(
      deleteFromCart({ product_id: productId, variation_id: variationId })
    );
  };

  return (
    <div className="w-screen max-w-md">
      <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
        <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
          <div className="flex items-start justify-between">
            <h2
              className="text-lg font-medium text-gray-900"
              id="slide-over-title"
            >
              Shopping cart
            </h2>
            <div className="ml-3 flex h-7 items-center">
              <button
                onClick={toggleCart}
                type="button"
                className="relative bg-slate-200 -m-2 p-2 text-gray-400 "
              >
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Close panel</span>
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="mt-8">
            <div className="flow-root">
              <ul role="list" className="-my-6 divide-y divide-gray-200">
                {carts &&
                  carts.map((item, index) => {
                    return (
                      <li key={index} className="flex py-6">
                        <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                          <img
                            src={getAssetUriWithSize(
                              "variations",
                              item.variation.latest_image
                                ? item.variation.latest_image.image
                                : 404,
                              300,
                              300
                            )}
                            alt="Front of satchel with blue canvas body, black straps and handle, drawstring top, and front zipper pouch."
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                        <div className="ml-4 flex flex-1 flex-col">
                          <div>
                            <div className="flex justify-between text-base font-medium text-gray-900">
                              <h3>
                                <Link
                                  to={PRODUCT_DETAIL_ROUTE(
                                    item.variation.id,
                                    encodeURIComponent(
                                      createSlug(item.variation.title)
                                    )
                                  )}
                                >
                                  {item.variation.title}
                                </Link>
                              </h3>
                              <p className="ml-4">
                                {formatAmountToINR(
                                  item.variation.active_price.price *
                                    item.quantity
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-1 items-end justify-between text-sm">
                            <p className="text-gray-500">Qty {item.quantity}</p>
                            <div className="flex">
                              <button
                                type="button"
                                onClick={() => {
                                  handleDelete(
                                    item.variation.product_id,
                                    item.variation.id
                                  );
                                }}
                                className="font-medium text-primary-600 hover:text-primary-500"
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-200 px-4 py-6 sm:px-6">
          <div className="flex justify-between text-base font-medium text-gray-900">
            <p>Subtotal</p>
            <p>{formatAmountToINR(calculateTotal())}</p>
          </div>
          <p className="mt-0.5 text-sm text-gray-500">
            Shipping and taxes calculated at checkout.
          </p>
          <div className="mt-6">
            <Link to={CHECKOUT_ROUTE}>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                className="w-full"
                tabIndex={-1}
                startIcon={<MdShoppingCartCheckout />}
              >
                Checkout
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
