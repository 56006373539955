import React, { useState } from "react";
import { deleteUserSavedAddress, getUserAddress } from "../../redux/orderSlice";
import { useDispatch } from "react-redux";
import { RiMenuAddFill } from "react-icons/ri";
import { toast } from "react-hot-toast";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import AddressCardBuilder from "./AddressCardBuilder";
import LoadingButton from "@mui/lab/LoadingButton";
import Input from "../utils/Input";
import { useFormik } from "formik";
import { checkOutSchema } from "../../validation/checkOutSchema";
import apiRequest from "../../utils/apiRequest";
const AddressCard = ({ address, className = "", selected = null }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(null);
  const [openAddAddress, setOpenAddAddress] = useState(false);
  const handleAddressDelete = async (id) => {
    setOpen(id);
  };
  const handleDelete = async () => {
    dispatch(deleteUserSavedAddress(open));
    setOpen(null);
  };

  const [validationError, setValidationError] = useState({});
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      postcode: "",
      address: "",
      locality: "",
    },
    validationSchema: checkOutSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        const response = await apiRequest.addUserSavedAddress(values);
        if (response.data.response) {
          toast.success(response.data.msg);
          dispatch(getUserAddress());
          formik.resetForm();
          setOpenAddAddress(false);
        } else {
          toast.error(response.data?.msg);
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setValidationError(error.response.data.errors);
        }
      }
    },
  });
  const handleSaveAddress = () => {
    formik.submitForm();
  };
  return (
    <div key={1}>
      <Button
        variant="outlined"
        onClick={() => setOpenAddAddress(true)}
        className="my-3"
        startIcon={<RiMenuAddFill />}
      >
        Add New Address
      </Button>

      <Dialog open={openAddAddress} onClose={() => setOpenAddAddress(false)}>
        <DialogTitle>Add New Address</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <div className="">
              <div className="grid md:grid-cols-2 gap-3 grid-cols-1">
                <div>
                  <Input
                    type={"text"}
                    label={"First Name"}
                    name={"first_name"}
                    placeholder={"Enter Your First Name"}
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.first_name}
                    serverError={validationError.first_name}
                    touched={formik.touched.first_name}
                  />
                </div>
                <div>
                  <Input
                    type={"text"}
                    label={"Last Name"}
                    name={"last_name"}
                    placeholder={"Enter Your First Name"}
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.last_name}
                    serverError={validationError.last_name}
                    touched={formik.touched.last_name}
                  />
                </div>
                <div>
                  <Input
                    type={"text"}
                    label={"mobile"}
                    name={"mobile"}
                    placeholder={"Enter Your Mobile No."}
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.mobile}
                    serverError={validationError.mobile}
                    touched={formik.touched.mobile}
                  />
                </div>
                <div>
                  <Input
                    type={"text"}
                    name={"email"}
                    label={"email"}
                    placeholder={"Enter Your Email Address"}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.email}
                    serverError={validationError.email}
                    touched={formik.touched.email}
                  />
                </div>

                <div>
                  <Input
                    type={"text"}
                    name={"address"}
                    placeholder={"Enter Your Address"}
                    value={formik.values.address}
                    onChange={formik.handleChange}
                    label={"Address"}
                    onBlur={formik.handleBlur}
                    error={formik.errors.address}
                    serverError={validationError.address}
                    touched={formik.touched.address}
                  />
                </div>
                <div>
                  <Input
                    type={"text"}
                    name={"locality"}
                    label={"locality"}
                    placeholder={"Enter Your locality"}
                    value={formik.values.locality}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.errors.locality}
                    serverError={validationError.locality}
                    touched={formik.touched.locality}
                  />
                </div>
              </div>
              <div>
                <Input
                  type={"text"}
                  name={"postcode"}
                  label={"postcode"}
                  placeholder={"Enter Your postcode"}
                  value={formik.values.postcode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.errors.postcode}
                  serverError={validationError.postcode}
                  touched={formik.touched.postcode}
                />
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddAddress(false)} color="secondary">
            No
          </Button>

          <LoadingButton
            onClick={handleSaveAddress}
            color="primary"
            variant="outlined"
            loading={formik.isSubmitting}
          >
            <span>Save</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog open={open !== null} onClose={() => setOpen(false)}>
        <DialogTitle>Delete Address</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this address?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(null)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <div className="item-body dashboard-wrapper w-full mt-4">
        <div className={`${className}`}>
          {address.map((item, index) => {
            return (
              <AddressCardBuilder
                key={index}
                index={index}
                address={item}
                onDelete={handleAddressDelete}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AddressCard;
