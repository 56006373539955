import React from "react";
import HeroSliderBlock from "../swiper/HeroSliderBlock";
import { useSelector } from "react-redux";
const Hero = () => {
  const { sliders } = useSelector(({ headerRedux }) => headerRedux);
  return (
    <>
      <HeroSliderBlock
        data={sliders}
        variant="slider"
        className={`xl:col-span-8 mb-5 xl:mb-12`}
        contentClassName="p-7 sm:py-18 xl:py-16 sm:px-16 xl:px-24 md:h-[270px] xl:h-[375px]"
      />
    </>
  );
};

export default Hero;
