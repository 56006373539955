import * as Yup from "yup";
export const checkOutSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(3)
    .max(100)
    .required("first name field is required"),
  last_name: Yup.string()
    .min(3)
    .max(100)
    .required("last name field is required"),
  email: Yup.string()
    .min(3)
    .max(100)
    .email("invalid email address")
    .required("email field is required"),
  address: Yup.string().min(3).max(255).required("Address field is required"),
  locality: Yup.string().min(3).max(255).required("locality field is required"),
  postcode: Yup.string().min(6).max(6).required("postcode field is required"),
  mobile: Yup.string()
    .matches(/^\d{10}$/, "Mobile No. must be exactly 10 digits")
    .required("Mobile No. field is required"),

    prescription_image: Yup.mixed()
    .nullable()
    .test("fileSize", "File size is too large", (value) => {
      if (!value) return true;
      const maxSize = 1048576;
      const fileSize =
        value.size ||
        (value.file && value.file.size) ||
        (value[0] && value[0].size);
      return fileSize <= maxSize;
    })
    .test("fileFormat", "Invalid file format", (value) => {
      if (!value) return true;
      const acceptedFormats = ["image/png", "image/jpeg", "application/pdf"];
      const fileType =
        value.type ||
        (value.file && value.file.type) ||
        (value[0] && value[0].type);
      return acceptedFormats.includes(fileType);
    }),
});
