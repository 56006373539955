import React from "react";
import ProductShimmer from "./ProductShimmer";

const ProductListShimmer = () => {
  return (
    <div className="overflow-hidden">
      <div className="h-12 w-full bg-slate-300 relative overflow-hidden">
        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-400 to-transparent animate-shimmer"></div>
      </div>
      <div className="mx-12  my-8">
        <div className="h-24 w-full rounded-lg mt-2 bg-slate-300 relative overflow-hidden">
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-400 to-transparent animate-shimmer"></div>
        </div>
      </div>
      <ProductShimmer />
    </div>
  );
};

export default ProductListShimmer;
