"use client";
import React, { useState } from "react";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FiMinimize } from "react-icons/fi";
import { PRODUCT_DETAIL_ROUTE } from "../../utils/router";
import { MdAddShoppingCart } from "react-icons/md";
import {
  addToCart,
  addToWishlist,
  deleteFromWishlist,
  setLoginOpen,
} from "../../redux/headerSlice";
import Rating from "@mui/material/Rating";
import IconButton from "@mui/material/IconButton";
import {
  formatAmountToINR,
  createSlug,
  getAssetUriWithSize,
} from "../../utils/helper";
import { useDispatch } from "react-redux";
import apiRequest from "../../utils/apiRequest";
import ProductDetail from "../product-details/ProductDetail";
import BottomSheetModal from "../global/BottomSheetModal";
import authManager from "../../utils/authManager";
import { Button } from "@mui/material";
function ProductListBuilder({ product, aos = "" }) {
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(null);
  const [productDetail, setProductDetail] = useState(null);
  const toggleDrawer = async (variationId) => {
    if (authManager.hasAccessToken()) {
      setProductDetail(null);
      setIsDrawerOpen(variationId);
    } else {
      dispatch(setLoginOpen(true));
    }

    try {
      const response = await apiRequest.fetchProductDetail(variationId);
      setProductDetail(response.data?.data);
    } catch (error) {
      throw new Error(error);
    }
  };
  const handleCloseDrawer = () => {
    setIsDrawerOpen(null);
  };
  const handleAddtoWishList = (variationId, productId) => {
    dispatch(
      addToWishlist({
        product_id: productId,
        variation_id: variationId,
        quantity: 1,
      })
    );
  };
  const handleWishListDelete = (variationId, productId) => {
    dispatch(
      deleteFromWishlist({
        product_id: productId,
        variation_id: variationId,
      })
    );
  };

  const handleAddToCart = (id, variation) => {
    dispatch(
      addToCart({
        product_id: id,
        variation_id: variation,
        quantity: 1,
      })
    );
  };
  return (
    <>
      <div
        data-aos="fade-up-left"
        className="flex gap-5 my-5 py-3 primaryShadow px-3 rounded-lg bg-white"
      >
        <div className="h-[200px] min-w-[200px] overflow-hidden rounded">
          <img
            src={getAssetUriWithSize(
              "variations",
              product.active_image ? product.active_image.image : 404,
              300,
              300
            )}
            alt={createSlug(product.title)}
            className="h-full w-full object-cover aspect-square"
          />
        </div>
        <div className="flex flex-col">
          <h1 className="text-x2s font-bold">{product.title}</h1>
          <div className="flex items-center gap-2">
            <Rating
              name="read-only"
              size="small"
              value={product.rating}
              readOnly
            />
          </div>
          <div className="inline-block my-4">
            <span className="text-x2s text-slate-500 line-clamp-2">
              {product.product.description}
            </span>
          </div>
          <div className="flex justify-center  w-full">
            <div className="flex rounded bg-red flex-row justify-center items-center">
              <IconButton
                onClick={() => toggleDrawer(product.id)}
                className="hoverNone text-sm"
              >
                <FiMinimize />
              </IconButton>

              {product.isFavorite ? (
                <IconButton
                  className="hoverNone text-sm"
                  onClick={() => {
                    handleWishListDelete(product.id, product.product_id);
                  }}
                >
                  <AiFillHeart className="text-red-500" />
                </IconButton>
              ) : (
                <IconButton
                  className="hoverNone text-sm"
                  onClick={() => {
                    handleAddtoWishList(product.id, product.product_id);
                  }}
                >
                  <AiOutlineHeart />
                </IconButton>
              )}

              <IconButton
                onClick={() => {
                  handleAddToCart(product.product_id, product.id);
                }}
                className="hoverNone text-sm"
              >
                <MdAddShoppingCart />
              </IconButton>
            </div>
          </div>
          <div className="inline-block">
            <span className="text-x2s">SKU: </span>
            <span className="text-x2s text-primary-500">{product.sku}</span>
          </div>
          <div className="flex gap-4 items-center justify-between">
            <div className="flex items-center gap-2">
              <span className="text-gray-900 font-bold text-x2s line-through">
                {formatAmountToINR(product.active_price.mrp)}
              </span>
              <span className="text-red-500 font-bold text-x2s">
                {formatAmountToINR(product.active_price.price)}
              </span>
            </div>
            <Link
              to={PRODUCT_DETAIL_ROUTE(
                product.id,
                encodeURIComponent(createSlug(product.title))
              )}
            >
              <Button variant="outlined" size="small" color="primary">
                View More
              </Button>
            </Link>
          </div>
        </div>
      </div>

      <BottomSheetModal
        isOpen={isDrawerOpen !== null}
        closeModal={handleCloseDrawer}
      >
        <div className="h-[70vh]">
          {productDetail && <ProductDetail productDetail={productDetail} />}
        </div>
      </BottomSheetModal>
    </>
  );
}

export default ProductListBuilder;
