import * as Yup from "yup";
export const RegisterFormSchema = Yup.object().shape({
  name: Yup.string().min(3).max(100).required("name field is required"),
  otp: Yup.string().min(4).max(4).required("otp field is required"),
  email: Yup.string()
    .min(3)
    .max(100)
    .email("invalid email address")
    .required("email field is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  mobile: Yup.string()
    .matches(/^\d{10}$/, "Mobile No. must be exactly 10 digits")
    .required("Mobile No. field is required"),
  confirm_password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password field is required"),
});

export const LoginFormSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
  mobile: Yup.string().matches(/^\d{10}$/, "Mobile No. must be exactly 10 digits")
    .required("Mobile No. field is required"),
});

export const reviewFormSchema = Yup.object().shape({
  title: Yup.string().required("title is required"),
  review: Yup.string().required("review is required"),
 
});
