import React, { useEffect, useState } from "react";
import ProductBuilder from "../components/product/ProductBuilder";
import apiRequest from "../utils/apiRequest";
import { Link, useParams } from "react-router-dom";
import ProductListShimmer from "../components/shimmer/ProductListShimmer";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../components/utils/Loader";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { HOME_ROUTE, SHOP_ROUTE } from "../utils/router";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { FaListUl } from "react-icons/fa";
import ProductListBuilder from "../components/product/ProductListBuilder";
import { Helmet } from "react-helmet";
const Shop = () => {
  const [products, setProducts] = useState([]);
  const [filterAttribute, setFilterAttribute] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedAttributes, setSelectedAttributes] = useState({});
  const [category, setCategory] = useState(null);
  const { id, name } = useParams();
  const [totalItems, setTotalItems] = useState(0);
  const [gridCount, setGridCount] = useState(5);
  const [paginate, setPaginate] = useState({
    offset: 0,
    limit: 20,
  });

  const handleFilter = (e) => {
    if (e.target.name === "brand") {
      setSelectedAttributes((prevState) => ({
        ...prevState,
        brand: [e.target.value],
      }));
    } else if (e.target.name === "color") {
      setSelectedAttributes((prevState) => ({
        ...prevState,
        color: [e.target.value],
      }));
    } else if (e.target.name === "price") {
      setSelectedAttributes((prevState) => ({
        ...prevState,
        price: e.target.value,
      }));
    } else if (e.target.name === "sort") {
      setSelectedAttributes((prevState) => ({
        ...prevState,
        sort: e.target.value,
      }));
    }
  };
  console.log(selectedAttributes);
  const filterAllProduct = async () => {
    const queryParams = Object.entries(
      Object.assign(
        {},
        selectedAttributes,
        { limit: paginate.limit, offset: 0 },
        { categoryId: id }
      )
    )
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return `${key}=${value.join(",")}`;
        } else {
          return `${key}=${value}`;
        }
      })
      .join("&");
    await apiRequest
      .getProduct(queryParams)
      .then((res) => {
        setProducts(res.data.data);
        setTotalItems(res.data?.total);
        setLoading(false);
        if (category === null) {
          setCategory(res.data.category);
        }
        setPaginate((prevPaginate) => ({
          ...prevPaginate,
          offset: parseInt(res.data.offset) + parseInt(res.data.limit),
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchMoreProduct = async () => {
    setPaginate((prevPaginate) => ({
      ...prevPaginate,
      offset: parseInt(prevPaginate.offset) + parseInt(prevPaginate.limit),
    }));
    const queryParams = Object.entries(
      Object.assign({}, selectedAttributes, paginate, { categoryId: id })
    )
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return `${key}=${value.join(",")}`;
        } else {
          return `${key}=${value}`;
        }
      })
      .join("&");
    await apiRequest
      .getProduct(queryParams)
      .then((res) => {
        setProducts([...products, ...res.data.data]);
        setPaginate((prevPaginate) => ({
          ...prevPaginate,
          offset: parseInt(res.data.offset) + parseInt(res.data.limit),
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllProductAttribute = async (id) => {
    await apiRequest
      .getCategoryWiseAttribute(id)
      .then((res) => {
        setFilterAttribute(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (Object.keys(selectedAttributes).length > 0) {
      filterAllProduct();
    }
  }, [selectedAttributes]);
  useEffect(() => {
    if (id) {
      setLoading(true);
      filterAllProduct();
      getAllProductAttribute(id);
    }
  }, [id]);

  return (
    <div>
      <Helmet>
        <title>{name.toUpperCase()} - Variety Mega Store</title>
      </Helmet>
      {!loading ? (
        <>
          <nav className="relative flex w-full flex-wrap items-center justify-between bg-dutchwhite py-2 shadow-dark-mild lg:py-4">
            <div className="flex w-full flex-wrap items-center justify-between px-3">
              <nav className="w-full rounded-md" aria-label="breadcrumb">
                <ol className="list-reset ms-2 flex capitalize">
                  <li>
                    <Link
                      key={0}
                      to={HOME_ROUTE}
                      className="text-sm hover:text-primary-500"
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <span className="mx-2 text-black/60">/</span>
                  </li>
                  {category &&
                    category.map((item, index) => {
                      return (
                        <div key={index} className="flex">
                          <li key={index + 1}>
                            <Link
                              to={SHOP_ROUTE(item.id, item.name)}
                              className="text-sm hover:text-primary-500"
                            >
                              {item.name.toLowerCase()}
                            </Link>
                          </li>
                          {index !== category.length - 1 && (
                            <li>
                              <span className="mx-2 text-black/60">/</span>
                            </li>
                          )}
                        </div>
                      );
                    })}
                </ol>
              </nav>
            </div>
          </nav>
          <div className="primaryShadow mx-3 rounded py-3 my-8 px-3 bg-white">
            <div className="grid md:grid-cols-3 grid-cols-1 place-items-center gap-4">
              <div className="grid grid-cols-3 place-items-center gap-3 w-full pr-3">
                <select
                  defaultValue={""}
                  id="small"
                  name="brand"
                  onChange={handleFilter}
                  className="block w-full p-2 text-sm text-gray-900 border focus:border-primary-500 border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:primary-blue-500 "
                >
                  {filterAttribute &&
                    filterAttribute["BRAND"] &&
                    [
                      { id: 0, value: "", label: "Brand" },
                      ...Object.entries(filterAttribute["BRAND"]).map(
                        ([key, value], index) => ({
                          id: key,
                          value: value,
                          label: value,
                        })
                      ),
                    ].map(({ id, value, label }) => (
                      <option key={id} value={value}>
                        {label}
                      </option>
                    ))}
                </select>
                <select
                  name="color"
                  defaultValue={""}
                  id="small"
                  onChange={handleFilter}
                  className="block w-full p-2 text-sm text-gray-900 border focus:border-primary-500 border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:primary-blue-500 "
                >
                  {filterAttribute &&
                    filterAttribute["COLOR"] &&
                    [
                      { id: 0, value: "", label: "Color" },
                      ...Object.entries(filterAttribute["COLOR"]).map(
                        ([key, value], index) => ({
                          id: key,
                          value: value,
                          label: value,
                        })
                      ),
                    ].map(({ id, value, label }) => (
                      <option key={id} value={value}>
                        {label}
                      </option>
                    ))}
                </select>
                <select
                  name="price"
                  defaultValue={""}
                  id="small"
                  onChange={handleFilter}
                  className="block w-full p-2 text-sm text-gray-900 border focus:border-primary-500 border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:primary-blue-500 "
                >
                  <option value={""}>Price</option>
                  <option value="0-50">0-50</option>
                  <option value="51-100">51-100</option>
                  <option value="101-250">101-250</option>
                  <option value="251-500">251-500</option>
                  <option value="501-1000">501-1000</option>
                  <option value="1001-Above">1001 & Above</option>
                </select>
              </div>
              <div className="gap-4 hidden md:flex">
                <div className="flex gap-4 mr-3">
                  <BsFillGrid3X3GapFill
                    className="text-primary-500  cursor-pointer"
                    onClick={() => setGridCount(5)}
                  />
                  <FaListUl
                    className="text-primary-500  cursor-pointer"
                    onClick={() => setGridCount(1)}
                  />
                </div>
                <div
                  className="flex gap-1 cursor-pointer"
                  onClick={() => setGridCount(3)}
                >
                  <div className="w-1 h-full bg-primary-500"></div>
                  <div className="w-1 h-full bg-primary-500"></div>
                  <div className="w-1 h-full bg-primary-500"></div>
                </div>
                <div
                  className="flex gap-1 cursor-pointer"
                  onClick={() => setGridCount(4)}
                >
                  <div className="w-1 h-full bg-primary-500"></div>
                  <div className="w-1 h-full bg-primary-500"></div>
                  <div className="w-1 h-full bg-primary-500"></div>
                  <div className="w-1 h-full bg-primary-500"></div>
                </div>
                <div
                  className="flex gap-1 cursor-pointer"
                  onClick={() => setGridCount(5)}
                >
                  <div className="w-1 h-full bg-primary-500"></div>
                  <div className="w-1 h-full bg-primary-500"></div>
                  <div className="w-1 h-full bg-primary-500"></div>
                  <div className="w-1 h-full bg-primary-500"></div>
                  <div className="w-1 h-full bg-primary-500"></div>
                </div>
              </div>
              <div className="flex">
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 120 }}
                  size="small"
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Sort
                  </InputLabel>
                  <Select
                    defaultValue={""}
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    onChange={handleFilter}
                    label="Sort"
                    name="sort"
                  >
                    <MenuItem value={""}>
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"ATOZ"}>A to Z</MenuItem>
                    <MenuItem value={"ZTOA"}>Z to A</MenuItem>
                    <MenuItem value={"HIGHTOLOW"}>High to Low</MenuItem>
                    <MenuItem value={"LOWTOHIGH"}>Low to High</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 120 }}
                  size="small"
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Show
                  </InputLabel>
                  <Select
                    onChange={(e) => {
                      setPaginate((prevPaginate) => ({
                        ...prevPaginate,
                        limit: e.target.value,
                      }));
                    }}
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    label="Show"
                    defaultValue={20}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={40}>40</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          <div className=" max-w-[1380px] mx-auto">
            <InfiniteScroll
              dataLength={products.length}
              next={fetchMoreProduct}
              hasMore={totalItems !== products.length}
              loader={<Loader className="pb-5" />}
            >
              {gridCount === 1 ? (
                <div
                  className={`container grid md:grid-cols-2 gap-3 py-3 overflow-hidden`}
                >
                  {products.map((item, index) => {
                    return (
                      <div key={index}>
                        <ProductListBuilder product={item} />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div
                  className={`container grid xxs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-${gridCount} xl:grid-cols-${gridCount} gap-3 py-3 overflow-hidden`}
                >
                  {products.map((item, index) => {
                    return (
                      <div key={index}>
                        <ProductBuilder product={item} />
                      </div>
                    );
                  })}
                </div>
              )}
            </InfiniteScroll>
          </div>
        </>
      ) : (
        <div className=" max-w-[1380px] mx-auto">
          <ProductListShimmer />
        </div>
      )}
    </div>
  );
};

export default Shop;
