import React from "react";
import Rating from "@mui/material/Rating";
import {
  createSlug,
  formatAmountToINR,
  getAssetUriWithSize,
} from "../../utils/helper";
import { PRODUCT_DETAIL_ROUTE } from "../../utils/router";
import { Link } from "react-router-dom";
const VerticleBuilder = ({ products }) => {
  return (
    <div className="md:px-0 px-4">
      {products.slice(0, 3).map((product) => (
        <Link
          key={product.id}
          to={PRODUCT_DETAIL_ROUTE(
            product.id,
            encodeURIComponent(createSlug(product.title))
          )}
        >
          <div
            key={product.id}
            className="flex border p-2 mb-2 rounded-lg items-center cursor-pointer bg-white"
          >
            <img
              src={getAssetUriWithSize(
                "variations",
                product.latest_image ? product.latest_image.image : 404,
                80,
                80
              )}
              alt={product.title}
              className="h-16 w-16 object-cover mr-4"
            />
            <div>
              <h3 className="text-x2s font-semibold mb-1 line-clamp-2">
                {product.title}
              </h3>
              <div className="flex items-center mb-1">
                <span className="text-gray-700 text-x2s">
                  {formatAmountToINR(product.active_price.price)}
                </span>
                {product.active_price.mrp && (
                  <span className="text-gray-500 line-through text-x2s ml-2">
                    {formatAmountToINR(product.active_price.mrp)}
                  </span>
                )}
              </div>
              <Rating
                size="small"
                name="read-only"
                value={product.rating}
                className="text-left"
                readOnly
              />
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default VerticleBuilder;
