import React from "react";
import ProductBuilder from "./ProductBuilder";
function Product({ products }) {
  return (
    <div className="container flex mx-auto px-4">
      <div className="grid xxs:grid-cols-2 items-stretch justify-items-stretch sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-7 gap-2 w-full">
        {products &&
          products.map((product, index) => (
            <div key={index}>
              <ProductBuilder product={product} />
            </div>
          ))}
      </div>
    </div>
  );
}

export default Product;
