import React, { useRef } from "react";
import { Swiper } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import cn from "classnames";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

export default function Carousel({
  children,
  className = "",
  buttonGroupClassName = "",
  prevActivateId = "",
  nextActivateId = "",
  prevButtonClassName = "start-3 xl:start-5",
  nextButtonClassName = "end-3 xl:end-5",
  buttonSize = "default",
  navigation = true,
  pagination = false,
  autoplay,
  ...props
}) {
  const dir = "ltr";
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const nextButtonClasses = cn(
    "swiper-next w-10 h-10 text-xl cursor-pointer flex items-center justify-center rounded-full bg-gray-300 hover:bg-primary absolute transition duration-300 focus:outline-none hover:text-white",
    nextButtonClassName
  );

  const prevButtonClasses = cn(
    "swiper-prev w-10 h-10 text-xl cursor-pointer flex items-center justify-center rounded-full bg-gray-300 hover:bg-primary absolute transition duration-300 focus:outline-none hover:text-white",
    prevButtonClassName
  );

  return (
    <div
      className={`carouselWrapper relative ${className} ${
        pagination ? "dotsCircle" : "dotsCircleNone"
      }`}
    >
      <Swiper
        modules={[Navigation, Autoplay, Pagination]}
        autoplay={autoplay}
        spaceBetween={20}
        slidesPerView={1}
        loop={true}
        pagination={pagination}
        navigation={{
          prevEl: prevActivateId.length
            ? `#${prevActivateId}`
            : prevRef.current,
          nextEl: nextActivateId.length
            ? `#${nextActivateId}`
            : nextRef.current,
        }}
        {...props}
      >
        {children}
      </Swiper>

      {Boolean(navigation) && (
        <div
          className={`flex items-center w-full absolute z-10 ${
            buttonGroupClassName
              ? buttonGroupClassName
              : "top-2/4 transform -translate-y-2/4"
          }`}
        >
          <div ref={prevRef} className={prevButtonClasses} id={prevActivateId}>
            {dir === "rtl" ? <IoIosArrowForward /> : <IoIosArrowBack />}
          </div>

          <div ref={nextRef} className={nextButtonClasses} id={nextActivateId}>
            {dir === "rtl" ? <IoIosArrowBack /> : <IoIosArrowForward />}
          </div>
        </div>
      )}
    </div>
  );
}
