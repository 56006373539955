import { Link } from "react-router-dom";
import { REGISTER_ROUTE } from "../../utils/router";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import Input from "../utils/Input";
import { LoginFormSchema } from "../../validation/authFormSchema";
import authManager from "../../utils/authManager";
import { useState } from "react";
import apiRequest from "../../utils/apiRequest";
import {
  fetchCartItem,
  fetchWishlistItem,
  setLoginOpen,
  setProfileData,
} from "../../redux/headerSlice";
import { useDispatch } from "react-redux";
export default function LoginForm() {
  const [validationError, setValidationError] = useState({
    mobile: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: { mobile: "", password: "" },
    validationSchema: LoginFormSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await apiRequest.loginUser(values);
        if (response.data && response.data.response === "true") {
          toast.success("Login Successfull");
          authManager.setAccessToken(response.data.access_token);
          dispatch(setProfileData(response.data.profile));
          dispatch(fetchCartItem());
          dispatch(fetchWishlistItem());
          formik.resetForm();
          dispatch(setLoginOpen(false));
        } else {
          toast.error(response.data?.msg);
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setValidationError(error.response.data.errors);
        }
      }
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="grid grid-cols-6 gap-6 w-full"
    >
      <div className="col-span-6">
        <label
          htmlFor="LastName"
          className="block text-sm font-medium text-gray-700"
        >
          Mobile
        </label>
        <Input
          type={"text"}
          name={"mobile"}
          placeholder={"Enter Your Mobile No."}
          value={formik.values.mobile}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.mobile}
          serverError={validationError.mobile}
          touched={formik.touched.mobile}
        />
      </div>
      <div className="col-span-6">
        <label
          htmlFor="Password"
          className="block text-sm font-medium text-gray-700"
        >
          Password
        </label>
        <Input
          type={"password"}
          name={"password"}
          placeholder={"Enter Your Password"}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.password}
          serverError={validationError.password}
          touched={formik.touched.password}
        />
      </div>

      <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
        <button
          type="submit"
          disabled={loading}
          className="inline-block shrink-0 rounded-md border border-accent-500 bg-accent-500 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-accent-500 focus:outline-none focus:ring active:text-accent-500"
        >
          Login
        </button>

        <p className="mt-4 text-sm text-gray-500 sm:mt-0">
          don't have an account?
          <Link to={REGISTER_ROUTE} className="text-primary-500 mx-1">
            Register Now
          </Link>
          .
        </p>
      </div>
    </form>
  );
}
