import Hero from "../components/home/Hero";
import { FaProductHunt } from "react-icons/fa6";
import { FaUserClock } from "react-icons/fa";
import { SiRoamresearch, SiIntigriti } from "react-icons/si";
import ProductSlider from "../components/product/ProductSlider";
import { flterProduct } from "../redux/headerSlice";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import ProductShimmer from "../components/shimmer/ProductShimmer";
import ProductFilterSlider from "../components/product/ProductFilterSlider";
import SaleOffer from "../assets/offer/sale-offer.jpg";
import Offer1 from "../assets/offer/offer-1.jpg";
import Offer2 from "../assets/offer/offer-2.jpg";
import Offer3 from "../assets/offer/offer-3.jpg";
import Category1 from "../assets/offer/category-1.jpg";
import VerticleBuilder from "../components/product/VerticleBuilder";
import { Helmet } from "react-helmet";
function Home() {
  const {
    products,
    art,
    office,
    diy,
    mixed,
    top_rated_product,
    best_seller_product,
    isProductFiltering,
    filterProducts,
  } = useSelector(({ headerRedux }) => headerRedux);
  const [value, setValue] = useState(2);
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    filterHomeProduct(newValue);
    setValue(newValue);
  };
  const filterHomeProduct = (newValue) => {
    let filter = null;
    if (newValue === 0) {
      filter = "NEW";
    } else if (newValue === 1) {
      filter = "FEATURED";
    }
    dispatch(flterProduct(filter));
  };
  return (
    <>
      <Helmet>
        <title>Variety Mega Store</title>
      </Helmet>
      <Hero />
      <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 mb-5">
        <div className=" py-3 rounded bg-white px-5">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={false}
            aria-label="scrollable auto tabs example"
          >
            <Tab
              label="NEW PRODUCTS"
              sx={{ fontWeight: "bold", fontSize: "16px" }}
            />
            <Tab
              label="FEATURED PRODUCTS"
              sx={{ fontWeight: "bold", fontSize: "16px" }}
            />
            <Tab
              label="PRODUCTS ON SALE"
              sx={{ fontWeight: "bold", fontSize: "16px" }}
            />
          </Tabs>
        </div>
        {isProductFiltering ? (
          <ProductShimmer length={5} />
        ) : (
          <ProductFilterSlider products={filterProducts} isLoading={false} />
        )}
      </div>

      <ProductSlider
        heading="ART STATIONERY"
        text="Discover the ideal lens that perfectly suits your vision needs and lifestyle."
        products={art}
      />
      <ProductSlider
        heading="MIXED MEDIA ART"
        text="Discover the ideal lens that perfectly suits your vision needs and lifestyle."
        products={mixed}
      />
      <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 mb-5">
        <div className="h-48 my-16 overflow-hidden" data-aos="zoom-in">
          <div
            className="container rounded-[10px] aspect-square"
            style={{
              backgroundImage: `url('${SaleOffer}')`,
              width: "100%",
              height: "100%",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </div>
      </div>
      <ProductSlider
        heading="OFFICE STATIONERY"
        text="Discover the ideal lens that perfectly suits your vision needs and lifestyle."
        products={office}
      />
      <ProductSlider
        heading="DIY"
        text="Discover the ideal lens that perfectly suits your vision needs and lifestyle."
        products={diy}
      />
      <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 mb-5">
        <div className=" relative py-3 rounded bg-white px-5">
          <h3 className="text-fill-base text-[16px] lg:leading-7 font-bold uppercase">
            {"Best Deals on Products"}
          </h3>
        </div>
        <div className="grid xxs:grid-cols-2 md:grid-cols-4 gap-5 mt-6">
          <div
            data-aos="fade-up-left"
            className=" gap-5 relative primaryShadow rounded-lg bg-white"
          >
            <div className="overflow-hidden rounded">
              <img
                src={Offer1}
                className="h-full w-full object-cover hover:scale-110 duration-300"
              />
            </div>
          </div>

          <div
            data-aos="fade-up-left"
            className=" gap-5 relative primaryShadow rounded-lg bg-white"
          >
            <div className=" overflow-hidden rounded">
              <img
                src={Offer2}
                className="h-full w-full object-cover hover:scale-110 duration-300"
              />
            </div>
          </div>

          <div
            data-aos="fade-up-left"
            className=" gap-5 relative primaryShadow rounded-lg bg-white"
          >
            <div className="overflow-hidden rounded">
              <img
                src={Offer3}
                className="h-full w-full object-cover hover:scale-110 duration-300"
              />
            </div>
          </div>

          <div
            data-aos="fade-up-left"
            className=" gap-5 relative primaryShadow rounded-lg bg-white"
          >
            <div className="overflow-hidden rounded">
              <img
                src={Offer1}
                className="h-full w-full object-cover hover:scale-110 duration-300"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 mb-5">
        <div className=" relative py-3 rounded bg-white px-5">
          <h3 className="text-fill-base text-[16px] lg:leading-7 font-bold uppercase">
            Top Rated Products
          </h3>
        </div>
        {isProductFiltering ? (
          <ProductShimmer length={5} />
        ) : (
          <ProductFilterSlider products={top_rated_product} isLoading={false} />
        )}
      </div>

      <div className="grid sm:grid-cols-2  xxs:grid-cols-1 items-center justify-center  lg:grid-cols-4 mb-3 mt-8 md:px-8 gap-5">
        <div>
          <h1 className={"text-xl md:text-xl font-bold mt-4 mb-2 ml-4 md:ml-0"}>
            Top Rated
          </h1>
          <VerticleBuilder
            products={
              top_rated_product.length > 1 ? top_rated_product : products
            }
          />
        </div>
        <div>
          <h1 className={"text-xl md:text-xl font-bold mt-4 mb-2 ml-4 md:ml-0"}>
            Most Selling Today
          </h1>
          <VerticleBuilder
            products={
              best_seller_product.length > 1 ? best_seller_product : products
            }
          />
        </div>
        <div>
          <h1 className={"text-xl md:text-xl font-bold mt-4 mb-2 ml-4 md:ml-0"}>
            Recent Purchased
          </h1>
          <VerticleBuilder
            products={
              best_seller_product.length > 1 ? best_seller_product : products
            }
          />
        </div>
        <div className=" md:mx-auto self-center flex justify-center mx-3">
          <img src={Category1} className="aspect-square rounded" />
        </div>
      </div>
      <div className=" py-3 rounded bg-white px-5">
        <h3 className="text-fill-base text-[16px] lg:leading-7 font-bold uppercase">
          Core Values
        </h3>

        <div className="lg:grid-cols-4 grid-cols-1 grid gap-5  flex-wrap bg-white py-5">
          <div className="flex flex-col items-center justify-center gap-3">
            <FaProductHunt className="text-5xl text-primary-500" />
            <p className="uppercase">Search For Product</p>
            <p className="text-xs -[250px] text-center">
              Search For Product i.e. Pen, Stationary or any other Product.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center gap-3">
            <FaUserClock className="text-5xl text-primary-500" />
            <p className="uppercase">Customer Focus</p>
            <p className="text-xs max-w-[250px] text-center">
              Delivering tailored solutions to Customer client needs.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center gap-3">
            <SiIntigriti className="text-5xl text-primary-500" />
            <p className="uppercase">Express Delivery</p>
            <p className="text-xs max-w-[250px] text-center">
              Swift shipping ensures your items arrive promptly.
            </p>
          </div>
          <div className="flex flex-col items-center justify-center gap-3">
            <SiRoamresearch className="text-5xl text-primary-500" />
            <p className="uppercase">Sustainability</p>
            <p className="text-xs max-w-[250px] text-center">
              Minimizing environmental footprint.
            </p>
          </div>
        </div>
      </div>
      <div className="px-3 py-6  text-black bg-dutchwhite">
        <div className="mx-auto flex flex-col items-center justify-between gap-6 sm:flex-row max-w-screen-lg">
          <div className="sm:w-7/12">
            <div className="text-3xl font-bold">Our Vision</div>
            <p className="mt-3  text-sm text-slate-600">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Culpa
              sit rerum incidunt, a consequuntur recusandae ab saepe illo est
              quia obcaecati neque quibusdam eius accusamus error officiis atque
              voluptates magnam!
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
