import React, { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../home/categorySelection.css";
import "../home/slick.css";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import IconButton from "@mui/material/IconButton";
import ProductBuilder from "./ProductBuilder";
import ProductShimmer from "../shimmer/ProductShimmer";
import { useSelector } from "react-redux";
export const CustomNextArrow = ({ onClick }) => {
  return (
    <div className="nextButton">
      <IconButton onClick={onClick}>
        <FiChevronRight style={{ fontSize: "24px", color: "#fff" }} />
      </IconButton>
    </div>
  );
};

export const CustomPrevArrow = ({ onClick }) => {
  return (
    <div className="prevButton">
      <IconButton onClick={onClick}>
        <FiChevronLeft style={{ fontSize: "24px", color: "#fff" }} />
      </IconButton>
    </div>
  );
};

const ProductSlider = ({ products, isLoading = true, heading = null }) => {
  const { homeDataloaded } = useSelector(({ headerRedux }) => headerRedux);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    arrows: false,
    nextArrow: false,
    prevArrow: false,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 305,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const sliderRef = useRef(null);

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <>
      {products?.length > 0 && (
        <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 mb-5">
          <div className=" relative py-3 rounded bg-white px-5">
            <h3 className="text-fill-base text-[16px] lg:leading-7 font-bold uppercase">
              {heading}
            </h3>
            <div className="absolute right-2 top-7">
              <div className="flex gap-[5px]">
                <CustomPrevArrow onClick={handlePrev} />
                <CustomNextArrow onClick={handleNext} />
              </div>
            </div>
          </div>
          <Slider ref={sliderRef} className="mt-6 w-full grid" {...settings}>
            {products?.length > 0 &&
              products.map((product, index) => (
                <div key={index}>
                  <ProductBuilder product={product} />
                </div>
              ))}
          </Slider>
        </div>
      )}
      {isLoading === true && !homeDataloaded && products?.length === 0 && (
        <ProductShimmer length={5} />
      )}
    </>
  );
};

export default ProductSlider;
