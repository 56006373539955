import React from "react";

import { FaTrashCan } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { setBillingAddress } from "../../redux/orderSlice";
const AddressCardBuilder = ({ address, onDelete, index }) => {
  const dispatch = useDispatch();
  const selectAddress = (address) => {
    dispatch(
      setBillingAddress({
        first_name: address.first_name,
        last_name: address.last_name,
        email: address.email,
        mobile: address.mobile,
        postcode: address.postcode,
        address: address.address,
        locality: address.locality,
      })
    );
  };
  return (
    <div>
      <div
        key={index}
        onClick={() => selectAddress(address)}
        className="w-full bg-primarygray p-5 border cursor-pointer"
      >
        <div className="flex justify-between items-center">
          <p className="title text-[22px] font-semibold">
            Address #{index + 1}
          </p>
          <button
            type="button"
            onClick={() => onDelete(address.id)}
            className="border border-qgray w-[34px] h-[34px] rounded-full flex justify-center items-center"
          >
            <FaTrashCan className="text-red-500" />
          </button>
        </div>
        <div className="mt-5">
          <table>
            <tbody>
              <tr className="flex mb-1">
                <td className="text-base text-qgraytwo w-[70px] block line-clamp-1">
                  <p>Name:</p>
                </td>
                <td className="text-base text-qblack line-clamp-1 font-medium">
                  {address.first_name + " " + address.last_name}
                </td>
              </tr>
              <tr className="flex mb-1">
                <td className="text-base text-qgraytwo w-[70px] block line-clamp-1">
                  <p>Email:</p>
                </td>
                <td className="text-base text-qblack line-clamp-1 font-medium">
                  {address.email}
                </td>
              </tr>
              <tr className="flex mb-1">
                <td className="text-base text-qgraytwo w-[70px] block line-clamp-1">
                  <p>Phone:</p>
                </td>
                <td className="text-base text-qblack line-clamp-1 font-medium">
                  {address.mobile}
                </td>
              </tr>
              <tr className="flex mb-1">
                <td className="text-base text-qgraytwo w-[70px] block line-clamp-1">
                  <p>Address:</p>
                </td>
                <td className="text-base text-qblack line-clamp-1 font-medium">
                  {address.address}, {address.locality} - {address.postcode}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AddressCardBuilder;
