import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../utils/apiRequest";
import { toast } from "react-hot-toast";
import authManager from "../utils/authManager";

export const getUserAddress = createAsyncThunk(
  "getUserAddress",
  async (data, thunkAPI) => {
    try {
      if (!authManager.hasAccessToken()) {
        return thunkAPI.rejectWithValue("Unauthorised Access");
      } else {
        const response = await apiRequest.getUserSavedAddress();
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const deleteUserSavedAddress = createAsyncThunk(
  "deleteUserSavedAddress",
  async (id, thunkAPI) => {
    try {
      if (!authManager.hasAccessToken()) {
        return thunkAPI.rejectWithValue("Unauthorised Access");
      } else {
        const response = await apiRequest.deleteUserSavedAddress(id);
        if (response.data.response) {
          thunkAPI.dispatch(getUserAddress());
        }
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
const orderSlice = createSlice({
  name: "order",
  initialState: {
    userAddress: [],
    selectedAddress: {
      first_name: "",
      last_name: "",
      email: "",
      mobile: "",
      postcode: "",
      address: "",
      locality: "",
      isSaved: null,
      file: null,
    },
  },
  reducers: {
    setBillingAddress(state, action) {
      state.selectedAddress = action.payload;
    },
    clearBillingAddress(state, action) {
      state.selectedAddress = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserAddress.fulfilled, (state, action) => {
        if (action.payload.response) {
          state.userAddress = action.payload.data;
        } else {
          toast.error(action.payload.msg);
        }
      })
      .addCase(deleteUserSavedAddress.fulfilled, (state, action) => {
        if (action.payload.response) {
          toast.success(action.payload.msg);
        } else {
          toast.error(action.payload.msg);
        }
      });
  },
});
export const { setBillingAddress, clearBillingAddress } = orderSlice.actions;
export default orderSlice.reducer;
