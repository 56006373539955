import React from "react";

const ProductDetailShimmer = ({ review = false }) => {
  return (
    <div className="container lg:max-w-7xl max-w-[1380px] mx-auto">
      {/* Top Grid with Two Shimmer Placeholders */}
      <div className="grid gap-2 md:grid-cols-2 py-5">
        <div>
          <div className="relative overflow-hidden h-[500px] bg-slate-200 rounded">
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
          </div>
          <div className="grid grid-cols-4 gap-5 my-5">
            <div className="relative overflow-hidden h-[100px] bg-slate-200 rounded">
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
            </div>
            <div className="relative overflow-hidden h-[100px] bg-slate-200 rounded">
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
            </div>
            <div className="relative overflow-hidden h-[100px] bg-slate-200 rounded">
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
            </div>
          </div>
        </div>

        <div className="relative overflow-hidden h-[400px] bg-slate-200 rounded">
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
        </div>
      </div>
      {review && (
        <div className="h-[400px] w-full max-w-[1380px] mx-auto rounded bg-slate-300 relative mt-5 overflow-hidden">
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-400 to-transparent animate-shimmer"></div>
        </div>
      )}
    </div>
  );
};

export default ProductDetailShimmer;
