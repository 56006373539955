import { configureStore } from "@reduxjs/toolkit";
import headerSlice from "./headerSlice";
import orderSlice from "./orderSlice";
const store = configureStore({
  reducer: {
    headerRedux: headerSlice,
    orderRedux: orderSlice,
  },
});

export default store;
