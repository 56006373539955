import React from "react";
import LoginForm from "../components/auth/LoginForm";
import { Card, CardContent } from "@mui/material";
import { Helmet } from "react-helmet";

const Login = () => {
  return (
    <div>
      <Helmet>
        <title>Login - Variety Mega Store</title>
      </Helmet>
      <div className="flex items-center justify-center w-full py-5">
        <Card>
          <CardContent>
            <LoginForm />
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Login;
