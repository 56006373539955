import React, { useState } from "react";
import Input from "../utils/Input";
import { RegisterFormSchema } from "../../validation/authFormSchema";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
const Profile = () => {
  const handleOtp = async () => {};
  const { profile } = useSelector(({ headerRedux }) => headerRedux);
  const [validationError, setValidationError] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    otp: "",
    confirm_password: "",
  });
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: profile.name,
      email: profile.email,
      mobile: profile.mobile,
      password: "",
      otp: "",
      confirm_password: "",
    },
    validationSchema: RegisterFormSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {},
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="mt-8 grid grid-cols-6 gap-6"
    >
      <div className="col-span-6 sm:col-span-3">
        <label
          htmlFor="FirstName"
          className="block text-sm font-medium text-gray-700"
        >
          Full Name
        </label>
        <Input
          type={"text"}
          name={"name"}
          placeholder={"Enter Your Name"}
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.name}
          serverError={validationError.name}
          touched={formik.touched.name}
        />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <label
          htmlFor="LastName"
          className="block text-sm font-medium text-gray-700"
        >
          Email
        </label>
        <Input
          type={"email"}
          name={"email"}
          placeholder={"Enter Your Email"}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.email}
          serverError={validationError.email}
          touched={formik.touched.email}
        />
      </div>

      <div className="col-span-6">
        <label
          htmlFor="mobile"
          className="block text-sm font-medium text-gray-700"
        >
          Mobile
        </label>
        <div className="relative">
          <Input
            type={"text"}
            name={"mobile"}
            disabled={isOtpSent}
            placeholder={"Enter Your Mobile No."}
            value={formik.values.mobile}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.mobile}
            serverError={validationError.mobile}
            touched={formik.touched.mobile}
          />
          <div className="absolute top-3 right-3 text-base">
            <div
              onClick={handleOtp}
              className="hover:text-accent-500 cursor-pointer"
            >
              {isOtpSent ? "Re-send OTP" : "Send OTP"}
            </div>
          </div>
        </div>
      </div>
      {isOtpSent && (
        <div className="col-span-6">
          <label
            htmlFor="Email"
            className="block text-sm font-medium text-gray-700"
          >
            Enter OTP
          </label>
          <Input
            type={"text"}
            name={"otp"}
            placeholder={"Enter 6 Digit OTP"}
            value={formik.values.otp}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.otp}
            serverError={validationError.otp}
            touched={formik.touched.otp}
          />
        </div>
      )}
      <div className="col-span-6 sm:col-span-3">
        <label
          htmlFor="Password"
          className="block text-sm font-medium text-gray-700"
        >
          Password
        </label>
        <Input
          type={"password"}
          name={"password"}
          placeholder={"Enter Your Password"}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.password}
          serverError={validationError.password}
          touched={formik.touched.password}
        />
      </div>

      <div className="col-span-6 sm:col-span-3">
        <label
          htmlFor="PasswordConfirmation"
          className="block text-sm font-medium text-gray-700"
        >
          Password Confirmation
        </label>

        <Input
          type={"password"}
          name={"confirm_password"}
          placeholder={"Confirm Your Password"}
          value={formik.values.confirm_password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.confirm_password}
          serverError={validationError.confirm_password}
          touched={formik.touched.confirm_password}
        />
      </div>
      <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
        <button
          type="submit"
          disabled={loading}
          className="inline-block shrink-0 rounded-md border border-accent-500 bg-accent-500 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-accent-500 focus:outline-none focus:ring active:text-accent-500"
        >
          Update
        </button>
      </div>
    </form>
  );
};

export default Profile;
