import React from "react";

const ProductShimmer = ({ length = 5 }) => {
  const productShimmerItems = Array.from(
    { length: length },
    (_, index) => index
  );

  return (
    <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 mb-5">
      <div className="container grid xxs:grid-cols-2 sm:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5 gap-3 mb-8">
        {productShimmerItems.map((index) => (
          <div key={index} className="product-card rounded p-3 shadow">
            <div className=" inline-flex w-full ">
              <div className="h-40 w-full rounded-lg mt-2 bg-slate-300 relative overflow-hidden">
                <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-400 to-transparent animate-shimmer"></div>
              </div>
            </div>
            <div className="h-10 w-full rounded-lg  bg-slate-300 relative overflow-hidden">
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
            </div>
            <div className="flex flex-col md:flex-row gap-3 mt-2">
              <div className="h-4 w-full rounded-lg bg-slate-300 relative overflow-hidden">
                <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
              </div>
              <div className="h-4 w-full rounded-lg bg-slate-300 relative overflow-hidden">
                <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-300 to-transparent animate-shimmer"></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductShimmer;
