import React from "react";

const Loader = ({className = ''}) => {
  return (
    <div>
      <div className={`flex justify-center items-center py-3 ${className}`}>
        <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    </div>
  );
};

export default Loader;
