import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../utils/apiRequest";
import { toast } from "react-hot-toast";
import authManager from "../utils/authManager";
// import { saveDataToFile } from "../../utils/fileSystem";

export const addToCart = createAsyncThunk(
  "addToCart",
  async (data, thunkAPI) => {
    try {
      if (!authManager.hasAccessToken()) {
        return thunkAPI.rejectWithValue("Unauthorised Access");
      } else {
        const response = await apiRequest.addToCart(data);
        if (response.data?.response) {
          thunkAPI.dispatch(fetchCartItem());
        }
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const flterProduct = createAsyncThunk(
  "flterProduct",
  async (filter, thunkAPI) => {
    try {
      const response = await apiRequest.flterProduct(filter);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addToWishlist = createAsyncThunk(
  "addToWishlist",
  async (data, thunkAPI) => {
    try {
      if (!authManager.hasAccessToken()) {
        return thunkAPI.rejectWithValue("Unauthorised Access");
      } else {
        const response = await apiRequest.addToWishlist(data);
        if (response.data?.response) {
          thunkAPI.dispatch(fetchWishlistItem());
        }
        return response.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const fetchCartItem = createAsyncThunk(
  "fetchCartItem",
  async (thunkAPI) => {
    try {
      const response = await apiRequest.getCartProduct();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const getUserOrder = createAsyncThunk(
  "getUserOrder",
  async (thunkAPI) => {
    try {
      const response = await apiRequest.getUserOrder();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const fetchWishlistItem = createAsyncThunk(
  "fetchWishlistItem",
  async (thunkAPI) => {
    try {
      const response = await apiRequest.getWishlistProduct();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const deleteFromCart = createAsyncThunk(
  "deleteFromCart",
  async (data, thunkAPI) => {
    try {
      const response = await apiRequest.deleteFromCart(data);
      if (response.data?.response) {
        thunkAPI.dispatch(fetchCartItem());
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const deleteFromWishlist = createAsyncThunk(
  "deleteFromWishlist",
  async (data, thunkAPI) => {
    try {
      const response = await apiRequest.deleteFromWishlist(data);
      if (response.data?.response) {
        thunkAPI.dispatch(fetchWishlistItem());
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const logoutUser = createAsyncThunk(
  "logoutUser",
  async (data, thunkAPI) => {
    try {
      const response = await apiRequest.logout();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const getHomeData = createAsyncThunk("getHomeData", async (thunkAPI) => {
  try {
    const response = await apiRequest.getHomeData();
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});
const headerSlice = createSlice({
  name: "header",
  initialState: {
    isLoginOpen: false,
    openNav: false,
    openCart: false,
    homeDataloaded: false,
    profile: null,
    sliders: [],
    filterProducts: [],
    carts: [],
    products: [],
    orders: [],
    art: [],
    diy: [],
    office: [],
    mixed: [],
    best_seller_product: [],
    top_rated_product: [],
    wishlists: [],
    categories: [],
    isProductFiltering: false,
  },
  reducers: {
    setLoginOpen(state, action) {
      state.isLoginOpen = action.payload;
    },
    setProfileData(state, action) {
      state.profile = action.payload;
    },
    setopenNav(state, action) {
      state.openNav = action.payload;
    },
    setopenCart(state, action) {
      state.openCart = action.payload;
    },
    initState(state) {
      state.isLoginOpen = false;
      state.openNav = false;
      state.openCart = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHomeData.fulfilled, (state, action) => {
        state.carts = action.payload?.carts;
        state.wishlists = action.payload?.wishlists;
        state.products = action.payload?.products;
        state.art = action.payload?.art;
        state.mixed = action.payload?.mixed;
        state.diy = action.payload?.diy;
        state.office = action.payload?.office;
        state.filterProducts = action.payload?.products;
        state.sliders = action.payload?.slider;
        state.profile = action.payload?.profile;
        state.categories = action.payload?.categories;
        state.best_seller_product = action.payload?.best_seller_product;
        state.top_rated_product = action.payload?.top_rated_product;
        state.homeDataloaded = true;
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        authManager.deleteAccessToken();
        state.profile = null;
        state.wishlists = [];
        state.carts = [];
      })
      .addCase(flterProduct.pending, (state) => {
        state.isProductFiltering = true;
      })
      .addCase(flterProduct.fulfilled, (state, action) => {
        if (action.payload?.products.length === 0) {
          toast.error("Products not Found");
        }
        state.filterProducts = action.payload?.products;
        state.isProductFiltering = false;
      })
      .addCase(flterProduct.rejected, (state) => {
        state.isProductFiltering = false;
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        if (action.payload.response) {
          toast.success(action.payload.msg);
        } else {
          toast.error(action.payload.msg);
        }
      })
      .addCase(addToCart.rejected, (state) => {
        state.isLoginOpen = true;
      })
      .addCase(getUserOrder.fulfilled, (state, action) => {
        state.orders = action.payload?.data;
      })
      .addCase(addToWishlist.fulfilled, (state, action) => {
        if (action.payload.response) {
          toast.success(action.payload.msg);
        } else {
          toast.error(action.payload.msg);
        }
      })
      .addCase(addToWishlist.rejected, (state, action) => {
        state.isLoginOpen = true;
      })
      .addCase(deleteFromCart.fulfilled, (state, action) => {
        if (!action.payload.response) {
          toast.error(action.payload.msg);
        } else {
          toast.success(action.payload.msg);
        }
      })
      .addCase(deleteFromWishlist.fulfilled, (state, action) => {
        if (!action.payload.response) {
          toast.error(action.payload.msg);
        } else {
          toast.success(action.payload.msg);
        }
      })
      .addCase(fetchCartItem.fulfilled, (state, action) => {
        if (action.payload.response) {
          state.carts = action.payload.data;
        } else {
          toast.error(action.payload.msg);
        }
      })
      .addCase(fetchWishlistItem.fulfilled, (state, action) => {
        if (action.payload.response) {
          state.wishlists = action.payload.data;
        } else {
          toast.error(action.payload.msg);
        }
      });
  },
});
export const {
  setLoginOpen,
  setopenNav,
  setopenCart,
  initState,
  setProfileData,
} = headerSlice.actions;
export default headerSlice.reducer;
