import React from "react";
import BreadCrump from "../assets/breadcrump/102402.jpg";
import { Helmet } from "react-helmet";
const ReturnExchange = () => {
  return (
    <div>
      <Helmet>
        <title>Return & Exchange - Variety Mega Store</title>
      </Helmet>
      <section
        className={`relative bg-cover bg-center bg-no-repeat h-36`}
        style={{ backgroundImage: `url(${BreadCrump})` }}
      >
        <div className="absolute top-10 left-5">
          <h1 className="text-2xl font-extrabold sm:text-2xl">
            <strong className="block font-extrabold text-white">
              Return & Exchange
            </strong>
          </h1>
        </div>
      </section>
      <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 mb-5 bg-white mt-5">
        <section>
          <div className="grid grid-cols-1 gap-12 lg:grid-cols-1 lg:gap-16">
            <div className="lg:py-24">
              <h2 className="text-3xl font-bold sm:text-4xl">
                Return & Exchange
              </h2>

              <p
                className="mt-4 text-gray-600"
                style={{ textAlign: "justify" }}
              >
                It seems like you're referring to "Lorem Ipsum," a placeholder
                text commonly used in the design and publishing industries. It's
                often used when the actual text content is not yet available or
                finalized. "Lorem Ipsum" is a pseudo-Latin text that has no
                meaning, allowing designers to focus on layout and presentation
                without being distracted by readable content. It seems like
                you're referring to "Lorem Ipsum," a placeholder text commonly
                used in the design and publishing industries. It's often used
                when the actual text content is not yet available or finalized.
                "Lorem Ipsum" is a pseudo-Latin text that has no meaning,
                allowing designers to focus on layout and presentation without
                being distracted by readable content. al text content is not yet
                available or finalized. "Lorem Ipsum" is a pseudo-Latin text
                that has no meaning, allowing designers to focus on layout and
                presentation without being distracted by readable content. It
                seems like you're referring to "Lorem Ipsum," a placeholder text
                commonly used in the design and publishing industries. It's
                often used when the actual text content is not yet available or
                finalized. "Lorem Ipsum" is a pseudo-Latin text that has no
                meaning, allowing designers to focus on layout and presentation
                without being distracted by readable content. It seems like
                you're referring to "Lorem Ipsum," a placeholder text commonly
                used in the design and publishing industries. It's often used
                when the actual text content is not yet available or finalized.
                "Lorem Ipsum" is a pseudo-Latin text that has no meaning,
                allowing designers to focus on layout and presentation without
                being distracted by readable content. It seems like you're
                referring to "Lorem Ipsum," a placeholder text commonly used in
                the design and publishing industries. It's often used when the
                actual text content is not yet available or finalized. "Lorem
                Ipsum" is a pseudo-Latin text that has no meaning, allowing
                designers to focus on layout and presentation without being
                distracted by readable content.
              </p>

              <p
                className="mt-4 text-gray-600"
                style={{ textAlign: "justify" }}
              >
                It seems like you're referring to "Lorem Ipsum," a placeholder
                text commonly used in the design and publishing industries. It's
                often used when the actual text content is not yet available or
                finalized. "Lorem Ipsum" is a pseudo-Latin text that has no
                meaning, allowing designers to focus on layout and presentation
                without being distracted by readable content. used in the design
                and publishing industries. It's often used when the actual text
                content is not yet available or finalized. "Lorem Ipsum" is a
                pseudo-Latin text that has no meaning, allowing designers to
                focus on layout and presentation without being distracted by
                readable content. publishing industries. It's often used when
                the actual text content is not yet available or finalized.
                "Lorem Ipsum" is a pseudo-Latin text that has no meaning,
                allowing designers to focus on layout and presentation without
                being distracted by readable content. It seems like you're
                referring to "Lorem Ipsum," a placeholder text commonly used in
                the design and publishing industries. It's often used when the
                actual text content is not yet available or finalized. "Lorem
                Ipsum" is a pseudo-Latin text that has no meaning, allowing
                designers to focus on layout and presentation without being
                distracted by readable content. It seems like you're referring
                to "Lorem Ipsum," a placeholder text commonly used in the design
                and publishing industries. It's often used when the actual text
                content is not yet available or finalized. "Lorem Ipsum" is a
                pseudo-Latin text that has no meaning, allowing designers to
                focus on layout and presentation without being distracted by
                readable content. It seems like you're referring to "Lorem
                Ipsum," a placeholder text commonly used in the design and
                publishing industries. It's often used when the actual text
                content is not yet available or finalized. "Lorem Ipsum" is a
                pseudo-Latin text that has no meaning, allowing designers to
                focus on layout and presentation without being distracted by
                readable content.
              </p>

              <p
                className="mt-4 text-gray-600"
                style={{ textAlign: "justify" }}
              >
                It seems like you're referring to "Lorem Ipsum," a placeholder
                text commonly used in the design and publishing industries. It's
                often used when the actual text content is not yet available or
                finalized. "Lorem Ipsum" is a pseudo-Latin text that has no
                meaning, allowing designers to focus on layout and presentation
                without being distracted by readable content. It seems like
                you're referring to "Lorem Ipsum," a placeholder text commonly
                used in the design and publishing industries. It's often used
                when the actual text content is not yet available or finalized.
                "Lorem Ipsum" is a pseudo-Latin text that has no meaning,
                allowing designers to focus on layout and presentation without
                being distracted by readable content. It seems like you're sed
                in the design and publishing industries. It's often used when
                the actual text content is not yet available or finalized.
                "Lorem Ipsum" is a pseudo-Latin text that has no meaning,
                allowing designers to focus on layout and presentation without
                being distracted by readable content. It seems like you're
                referring to "Lorem Ipsum," a placeholder text commonly used in
                the design and publishing industries. It's often used when the
                actual text content is not yet available or finalized. "Lorem
                Ipsum" is a pseudo-Latin text that has no meaning, allowing
                designers to focus on layout and presentation without being
                distracted by readable content. It seems like you're referring
                to "Lorem Ipsum," a placeholder text commonly used in the design
                and publishing industries. It's often used when the actual text
                content is not yet available or finalized. "Lorem Ipsum" is a
                pseudo-Latin text that has no meaning, allowing designers to
                focus on layout and presentation without being distracted by
                readable content.
              </p>

              <p
                className="mt-4 text-gray-600"
                style={{ textAlign: "justify" }}
              >
                It seems like you're referring to "Lorem Ipsum," a placeholder
                text commonly used in the design and publishing industries. It's
                often used when the actual text content is not yet available or
                finalized. "Lorem Ipsum" is a pseudo-Latin text that has no
                meaning, allowing designers to focus on layout and presentation
                without being distracted by readable content. It seems like
                you're text commonly used in the design and publishing
                industries. It's often used when the actual text content is not
                yet available or finalized. "Lorem Ipsum" is a pseudo-Latin text
                that has no meaning, allowing designers to focus on layout and
                presentation without being distracted by readable content. It
                seems like you're referring to "Lorem Ipsum," a placeholder text
                commonly used in the design and publishing industries. It's
                often used when the actual text content is not yet available or
                finalized. "Lorem Ipsum" is a pseudo-Latin text that has no
                meaning, allowing designers to focus on layout and presentation
                without being distracted by readable content. It seems like
                you're referring to "Lorem Ipsum," a placeholder text commonly
                used in the design and publishing industries. It's often used
                when the actual text content is not yet available or finalized.
                "Lorem Ipsum" is a pseudo-Latin text that has no meaning,
                allowing designers to focus on layout and presentation without
                being distracted by readable content.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ReturnExchange;
