import React, { useState } from "react";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { Link } from "react-router-dom";
import { FiMinimize } from "react-icons/fi";
import { PRODUCT_DETAIL_ROUTE } from "../../utils/router";
import { MdAddShoppingCart } from "react-icons/md";
import {
  addToCart,
  addToWishlist,
  deleteFromWishlist,
  setLoginOpen,
} from "../../redux/headerSlice";
import Rating from "@mui/material/Rating";
import IconButton from "@mui/material/IconButton";
import { formatAmountToINR, createSlug, getAssetUri } from "../../utils/helper";
import { useDispatch } from "react-redux";
import apiRequest from "../../utils/apiRequest";
import ProductDetail from "../product-details/ProductDetail";
import BottomSheetModal from "../global/BottomSheetModal";
import authManager from "../../utils/authManager";
function ProductBuilder({ product, aos = "" }) {
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOpen] = useState(null);
  const [productDetail, setProductDetail] = useState(null);
  const toggleDrawer = async (variationId) => {
    if (authManager.hasAccessToken()) {
      setProductDetail(null);
      setIsDrawerOpen(variationId);
    } else {
      dispatch(setLoginOpen(true));
    }

    try {
      const response = await apiRequest.fetchProductDetail(variationId);
      setProductDetail(response.data?.data);
    } catch (error) {
      throw new Error(error);
    }
  };
  const handleCloseDrawer = () => {
    setIsDrawerOpen(null);
  };
  const handleAddtoWishList = (variationId, productId) => {
    dispatch(
      addToWishlist({
        product_id: productId,
        variation_id: variationId,
        quantity: 1,
      })
    );
  };
  const handleWishListDelete = (variationId, productId) => {
    dispatch(
      deleteFromWishlist({
        product_id: productId,
        variation_id: variationId,
      })
    );
  };

  const handleAddToCart = (id, variation) => {
    dispatch(
      addToCart({
        product_id: id,
        variation_id: variation,
        quantity: 1,
      })
    );
  };
  return (
    <>
      <div className=" inline-flex pb-4 w-full px-2" data-aos={aos}>
        <div className=" hover:border-accent-500 border-2  group relative shadow overflow-hidden border-slate-200  bg-white items-stretch w-full h-full p-0 box-border">
          <div className="absolute flex justify-center  w-full top-10 right-0">
            <div className="flex rounded bg-white scale-0 group-hover:scale-100 duration-200 shadow flex-row justify-center items-center">
              <IconButton
                onClick={() => toggleDrawer(product.id)}
                className="hoverNone text-sm"
              >
                <FiMinimize />
              </IconButton>

              {product.isFavorite ? (
                <IconButton
                  className="hoverNone text-sm"
                  onClick={() => {
                    handleWishListDelete(product.id, product.product_id);
                  }}
                >
                  <AiFillHeart className="text-red-500" />
                </IconButton>
              ) : (
                <IconButton
                  className="hoverNone text-sm"
                  onClick={() => {
                    handleAddtoWishList(product.id, product.product_id);
                  }}
                >
                  <AiOutlineHeart />
                </IconButton>
              )}

              <IconButton
                onClick={() => {
                  handleAddToCart(product.product_id, product.id);
                }}
                className="hoverNone text-sm"
              >
                <MdAddShoppingCart />
              </IconButton>
            </div>
          </div>
          <Link
            to={PRODUCT_DETAIL_ROUTE(
              product.id,
              encodeURIComponent(createSlug(product.title))
            )}
          >
            <div className=" rounded-lg min-w-full flex flex-col">
              <div className="overflow-hidden max-w-[100%]">
                <img
                  src={getAssetUri(
                    "variations",
                    product.latest_image ? product.latest_image.image : 404
                  )}
                  placeholder="blur"
                  height={200}
                  width={200}
                  loading="lazy"
                  alt={createSlug(product.title)}
                  className="h-full w-full object-contain"
                />
              </div>
              <div className="p-4">
                <h2 className="mb-2 line-clamp-3 text-x1s  text-gray-900">
                  {product.title}
                </h2>
                <div className="flex flex-col items-start">
                  <Rating
                    size="small"
                    name="read-only"
                    value={product.rating}
                    className="text-left"
                    readOnly
                  />
                </div>

                <div className="flex items-center justify-between">
                  <p className="mr-2 text-sm  font-bold  text-green-600">
                    {formatAmountToINR(product.active_price?.price)}
                  </p>
                  <p className="text-x2s text-red-500 line-through ">
                    {formatAmountToINR(product.active_price?.mrp)}
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>

      <BottomSheetModal
        isOpen={isDrawerOpen !== null}
        closeModal={handleCloseDrawer}
      >
        <div className="h-[70vh]">
          {productDetail && <ProductDetail productDetail={productDetail} />}
        </div>
      </BottomSheetModal>
    </>
  );
}

export default ProductBuilder;
