import React from "react";
import { BottomSheet } from "react-spring-bottom-sheet";

const BottomSheetModal = ({ isOpen, closeModal, children }) => {
  return (
    <div>
      <BottomSheet open={isOpen} onDismiss={closeModal}>
        <div className="max-h-[80vh] overflow-x-hidden">
        {children}
        </div>
      </BottomSheet>
    </div>
  );
};

export default BottomSheetModal;
