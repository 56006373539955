import { createSlug } from "./helper";
export const HOME_ROUTE = "/";
export const PRIVACY_POLICY_ROUTE = "/privacy-policy";
export const ABOUT_ROUTE = "/about";
export const RETURN_AND_EXCHANGE_ROUTE = "/return-and-exchange";
export const TERMS_AND_CONDITION_ROUTE = "/terms-and-condition";
export const CONTACT_ROUTE = "/contact-us";
export const REGISTER_ROUTE = "/register";
export const WISHLIST_ROUTE = "/wishlist";
export const CHECKOUT_ROUTE = "/checkout";
export const MY_ACCOUNT_ROUTE = "/my-account";
export const LOGIN_ROUTE = "/login";
export const PRODUCT_DETAIL_ROUTE = (id, title) => {
  return `/product-detail/${id}/${title}`;
};
export const SHOP_ROUTE = (id, category) => {
  return `/shop/${id}/${createSlug(category)}`;
};
// NOt Defined
export const SEARCH_ROUTE = (title) => {
  return `/search/${title}`;
};
