import React, { useEffect, useState } from "react";
import ProductBuilder from "../components/product/ProductBuilder";
import apiRequest from "../utils/apiRequest";
import { useParams } from "react-router-dom";
import ProductListShimmer from "../components/shimmer/ProductListShimmer";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../components/utils/Loader";

const Search = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { title } = useParams();
  const [totalItems, setTotalItems] = useState(0);
  const [paginate, setPaginate] = useState({
    offset: 0,
    limit: 10,
  });

  const filterAllProduct = async () => {
    const queryParams = Object.entries({
      limit: paginate.limit,
      offset: 0,
      searchQuery: title,
    })
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return `${key}=${value.join(",")}`;
        } else {
          return `${key}=${value}`;
        }
      })
      .join("&");
    await apiRequest
      .getProduct(queryParams)
      .then((res) => {
        setProducts(res.data.data);
        setTotalItems(res.data?.total);
        setLoading(false);
        setPaginate((prevPaginate) => ({
          ...prevPaginate,
          offset: res.data.offset + res.data.limit,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchMoreProduct = async () => {
    setPaginate((prevPaginate) => ({
      ...prevPaginate,
      offset: prevPaginate.offset + prevPaginate.limit,
    }));
    const queryParams = Object.entries(
      Object.assign({}, { searchQuery: title }, paginate)
    )
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return `${key}=${value.join(",")}`;
        } else {
          return `${key}=${value}`;
        }
      })
      .join("&");
    await apiRequest
      .getProduct(queryParams)
      .then((res) => {
        setProducts([...products, ...res.data.data]);
        setPaginate((prevPaginate) => ({
          ...prevPaginate,
          offset: res.data.offset + res.data.limit,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (title) {
      setLoading(true);
      filterAllProduct();
    }
  }, [title]);
  return (
    <div>
      {!loading ? (
        <InfiniteScroll
          dataLength={products.length}
          next={fetchMoreProduct}
          hasMore={totalItems !== products.length}
          loader={<Loader className="pb-5" />}
        >
          <div className="lg:col-span-3 grid xxs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-7 gap-3 px-4 py-3">
            {products.map((item, index) => {
              return (
                <div key={index}>
                  <ProductBuilder product={item} />
                </div>
              );
            })}
          </div>
        </InfiniteScroll>
      ) : (
        <ProductListShimmer />
      )}
    </div>
  );
};

export default Search;
