import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import authManager from "../../utils/authManager";
import { HOME_ROUTE } from "../../utils/router";
import { setLoginOpen } from "../../redux/headerSlice";
import { useDispatch } from "react-redux";

export default function UserAuthWrapper({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const openLogin = () => {
    dispatch(setLoginOpen(true));
  };
  useEffect(() => {
    if (!authManager.hasAccessToken()) {
      openLogin();
      navigate(HOME_ROUTE);
    }
  }, [navigate]);

  return authManager.hasAccessToken() ? children : null;
}
