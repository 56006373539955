const webUrl = "https://project.varietymegastore.com/";
const baseUrl = webUrl + "uploads/";

export function formatAmountToINR(amount) {
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
  });
  return formatter.format(amount);
}

export function toTitleCase(str) {
  return str
    .toLowerCase()
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function createSlug(input) {
  if (input) {
    return input
      .toLowerCase()
      .replace(/[^a-z0-9-\s]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-")
      .replace(/^-|-$/g, "");
  } else {
    return "NA";
  }
}
export function getAssetUri(folder, filename) {
  return baseUrl + folder + "/" + filename;
}
export function getRecieptUrl(id, title) {
  return webUrl + "reciept/" + id + "/" + title + ".pdf";
}
export function getAssetUriWithSize(folder, filename, height, width) {
  return (
    baseUrl + "resize/" + folder + "/" + height + "/" + width + "/" + filename
  );
}
export function stringToColor(string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

export function stringAvatar(name) {
  let children = "";
  const nameWords = name.split(" ");

  if (nameWords.length >= 2) {
    children = `${nameWords[0][0]}${nameWords[1][0]}`;
  } else if (nameWords.length === 1) {
    children = nameWords[0][0];
  }
  return {
    sx: {
      width: 35,
      height: 35,
      fontSize: 12,
      bgcolor: stringToColor(name),
    },
    children: children,
  };
}

export function timeAgo(timestamp) {
  const currentTime = new Date();
  const time = new Date(timestamp);

  if (isNaN(time.getTime())) {
    return "Invalid date";
  }

  const seconds = Math.floor((currentTime - time) / 1000);

  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return interval + " years ago";
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + " months ago";
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + " days ago";
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return interval + " hours ago";
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
}
export function formatDate(timestamp) {
  const date = new Date(timestamp);
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  return date.toLocaleDateString("en-GB", options);
}
export function cleanTitle(title) {
  let parts = title.split("|");
  if (parts.length > 2) {
    parts = parts.slice(1, -1);
  }
  return parts.join("|").trim();
}
export function convertYouTubeUrlToEmbedUrl(url) {
  const urlObj = new URL(url);
  let videoId;
  if (urlObj.searchParams.get("v")) {
    videoId = urlObj.searchParams.get("v");
  } else {
    const pathSegments = urlObj.pathname.split("/");
    videoId = pathSegments[pathSegments.length - 1];
  }

  if (videoId) {
    return `https://www.youtube.com/embed/${videoId}`;
  } else {
    throw new Error("Invalid YouTube URL");
  }
}
