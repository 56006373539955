import HeroBannerCard from "./HeroBannerCard";
import Carousel from "./CarouselBuilder";
import { SwiperSlide } from "swiper/react";

const HeroSliderBlock = ({
  variant = "slider",
  className = "mb-7",
  contentClassName = "px-5 py-10 xl:py-24",
  data,
}) => {
  return (
    <div className={`${className}`}>
      <Carousel
        pagination={{
          clickable: true,
        }}
        navigation={true}
        autoplay={false}
        prevActivateId={`prevActivateId`}
        nextActivateId={`nextActivateId`}
      >
        {data?.length > 0 ? (
          data?.map((banner) => (
            <SwiperSlide key={`banner--key${banner.id}`}>
              <HeroBannerCard
                banner={banner}
                variant={variant}
                className={contentClassName}
              />
            </SwiperSlide>
          ))
        ) : (
          <div className="h-[380px] w-[100%] mx-auto rounded bg-slate-300 relative overflow-hidden">
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-slate-400 to-transparent animate-shimmer"></div>
          </div>
        )}
      </Carousel>
    </div>
  );
};

export default HeroSliderBlock;
