import React from "react";
import BreadCrump from "../assets/breadcrump/102402.jpg";
import { Helmet } from "react-helmet";
const PrivacyPolicy = () => {
  return (
    <div>
      <Helmet>
        <title>Privacy & Policy - Variety Mega Store</title>
      </Helmet>
      <section
        className={`relative bg-cover bg-center bg-no-repeat h-36`}
        style={{ backgroundImage: `url(${BreadCrump})` }}
      >
        <div className="absolute top-10 left-5">
          <h1 className="text-2xl font-extrabold sm:text-2xl">
            <strong className="block font-extrabold text-white">
              Privacy Policy
            </strong>
          </h1>
        </div>
      </section>

      <div className="mx-auto max-w-[1380px] px-4 md:px-6 3xl:px-0 mb-5 bg-white mt-5">
        <section>
          <section>
            <div className="container ">
              <div className="grid grid-cols-1 gap-12 lg:grid-cols-1 lg:gap-16">
                <div className="lg:py-4">
                  <h2 className="text-3xl font-bold sm:text-4xl mb-4">
                    Privacy Policy
                  </h2>
                  At Variety Mega Store, we are committed to protecting your
                  privacy. This Privacy Policy outlines how we collect, use,
                  disclose, and safeguard your information when you visit our
                  website and use our services. Please read this policy
                  carefully to understand our views and practices regarding your
                  personal data and how we will treat it.
                  <h2 className="text-xl mb-4">Information We Collect</h2>
                  <p className="mb-4">
                    We may collect and process the following data about you:
                  </p>
                  <ul className="list-disc ml-6 mb-4">
                    <li>
                      Personal Identification Information:
                      <ul className="list-disc ml-6">
                        <li>Name</li>
                        <li>Email address</li>
                        <li>Phone number</li>
                        <li>Address</li>
                      </ul>
                    </li>
                    <li>
                      Usage Data: Information about how you use our website and
                      services
                    </li>
                    <li>
                      Marketing and Communications Data: Your preferences in
                      receiving marketing from us and your communication
                      preferences
                    </li>
                  </ul>
                  <h2 className="text-xl mb-4">How We Use Your Information</h2>
                  <ul className="list-disc ml-6 mb-4">
                    <li>
                      To Provide and Maintain Our Service: To provide and manage
                      our air conditioning servicing and installation services
                    </li>
                    <li>
                      To Improve Customer Service: Information you provide helps
                      us respond to your customer service requests and support
                      needs more efficiently
                    </li>
                    <li>
                      To Process Transactions: We may use the information you
                      provide about yourself when placing an order to provide
                      service to that order. We do not share this information
                      with outside parties except to the extent necessary to
                      provide the service.
                    </li>
                    <li>
                      To Send Periodic Emails: We may use the email address to
                      send you information and updates pertaining to your order
                      or service. It may also be used to respond to your
                      inquiries, questions, and/or other requests.
                    </li>
                  </ul>
                  <h2 className="text-xl mb-4">Sharing Your Information</h2>
                  <p className="mb-4">
                    We do not sell, trade, or otherwise transfer to outside
                    parties your Personally Identifiable Information unless we
                    provide users with advance notice. This does not include
                    website hosting partners and other parties who assist us in
                    operating our website, conducting our business, or serving
                    our users, so long as those parties agree to keep this
                    information confidential.
                  </p>
                  <h2 className="text-xl mb-4">Data Security</h2>
                  <p className="mb-4">
                    We use administrative, technical, and physical security
                    measures to help protect your personal information. While we
                    have taken reasonable steps to secure the personal
                    information you provide to us, please be aware that despite
                    our efforts, no security measures are perfect or
                    impenetrable, and no method of data transmission can be
                    guaranteed against any interception or other type of misuse.
                  </p>
                  <h2 className="text-xl mb-4">Your Data Protection Rights</h2>
                  <ul className="list-disc ml-6 mb-4">
                    <li>
                      The Right to Access: You have the right to request copies
                      of your personal data.
                    </li>
                    <li>
                      The Right to Rectification: You have the right to request
                      that we correct any information you believe is inaccurate.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
