const authManager = {
  setAccessToken: (accessToken) => {
    try {
      localStorage.removeItem("access_token");
      localStorage.setItem("access_token", JSON.stringify(accessToken));
    } catch (error) {
      console.error("Error setting access token:", error);
    }
  },
  getAccessToken: () => {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("access_token")) {
        return JSON.parse(localStorage.getItem("access_token"));
      }
      return false;
    }
    return false;
  },
  deleteAccessToken: () => {
    localStorage.removeItem("access_token");
  },
  hasAccessToken: () => {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("access_token")) {
        return !!localStorage.getItem("access_token");
      }
      return false;
    }
    return false;
  },
};

export default authManager;
