import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaTruck, FaShieldAlt } from "react-icons/fa";
import { FaCartPlus, FaShoppingCart } from "react-icons/fa";
import { formatAmountToINR, getAssetUri, timeAgo, toTitleCase } from "../utils/helper";
import { Link, useParams } from "react-router-dom";
import apiRequest from "../utils/apiRequest";
import { addToCart } from "../redux/headerSlice";
import "react-spring-bottom-sheet/dist/style.css";
import { useDispatch } from "react-redux";
import { CHECKOUT_ROUTE } from "../utils/router";
import ProductDetailShimmer from "../components/shimmer/ProductDetailShimmer";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { Helmet } from "react-helmet";
import {
  Button,
  Paper,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useFormik } from "formik";
import Input from "../components/utils/Input";
import toast from "react-hot-toast";
import TextArea from "../components/utils/TextArea";
import Loader from "../components/utils/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { reviewFormSchema } from "../validation/authFormSchema";

export function ProductDetail() {
  const dispatch = useDispatch();

  const { id, title } = useParams();
  const sliderRef = useRef(null);
  const thumbnailSliderRef = useRef(null);
  const [productDetail, setProductDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [reviewData, setReviewData] = useState(null);
  const [mainSliderIndex, setMainSliderIndex] = useState(0);
  const getProductDetail = async (applyLoader = true) => {
    if (!id) return;
    if (applyLoader) {
      setLoading(true);
    }
    try {
      const res = await apiRequest.fetchProductDetail(id);
      setProductDetail(res.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const fetchMoreReview = async () => {
    const params = new URLSearchParams({
      product_id: productDetail.product_id,
      variation_id: productDetail.id,
      offset: parseInt(reviewData.offset) + parseInt(reviewData.limit),
      limit: 4,
    });
    try {
      const res = await apiRequest.getProductReview(params.toString());
      setReviewData((prevState) => {
        const newData = res.data.data;
        const updatedData = [...prevState.data, ...newData];
        const updatedOffset = res.data.offset;
        return {
          ...prevState,
          data: updatedData,
          offset: parseInt(updatedOffset),
        };
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getProductReview = async () => {
    const params = new URLSearchParams({
      product_id: productDetail.product_id,
      variation_id: productDetail.id,
      limit: 4,
      offset: 0,
    });
    try {
      const res = await apiRequest.getProductReview(params.toString());
      setReviewData(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (productDetail && productDetail.id) {
      getProductReview();
    }
  }, [productDetail]);
  useEffect(() => {
    if (id) {
      getProductDetail();
    }
  }, [id]);
  const handleAddToCart = () => {
    dispatch(
      addToCart({
        product_id: productDetail.product.id,
        variation_id: productDetail.id,
        quantity: 1,
      })
    );
  };

  const [validationError, setValidationError] = useState({
    title: "",
    review: "",
    rating: "",
  });
  const [currentTab, setCurrentTab] = useState(0);

  const formik = useFormik({
    initialValues: { title: "", review: "", rating: 1 },
    validationSchema: reviewFormSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      try {
        const response = await apiRequest.saveProductReview(
          Object.assign({}, values, {
            product_id: productDetail.product_id,
            variation_id: productDetail.id,
          })
        );
        if (response.data.response) {
          toast.success(response.data.msg);
          const updatedReviews = [...reviewData.data];
          updatedReviews.push(response.data.review);
          setReviewData({ data: updatedReviews });
          getProductDetail(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setValidationError(error.response.data.errors);
        }
      }
    },
  });
  const handleRatingChange = (event, newValue) => {
    formik.setFieldValue("rating", newValue);
  };

  const mainSliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    fade: true,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (index) => setMainSliderIndex(index),
  };
  const thumbnailSliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    focusOnSelect: true,
  };

  const handleThumbnailClick = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  return (
    <div>
      <Helmet>
        <title>{`${toTitleCase(title)} - Variety Mega Store`}</title>
      </Helmet>
      {loading || !productDetail || !productDetail.active_price ? (
        <div className="p-5">
          <ProductDetailShimmer review={true} />
        </div>
      ) : (
        <>
          <div>
            <div className="container lg:max-w-7xl max-w-4xl mx-auto">
              <div className="grid gap-2 md:grid-cols-2 md:px-0 px-5 py-5">
                <div className="grid grid-cols-1">
                  <div className="border-slate-400 productDetail productZoom p-0 m-0">
                    <Slider ref={sliderRef} {...mainSliderSettings}>
                      {productDetail.images &&
                        productDetail.images.map((image, index) => (
                          <div key={index} className="item">
                            <InnerImageZoom
                              zoomType="hover"
                              zoomScale={1}
                              src={getAssetUri("variations", image.image)}
                            />
                          </div>
                        ))}
                    </Slider>
                  </div>
                  <Slider
                    className="flex justify-center zoomSlider"
                    ref={thumbnailSliderRef}
                    {...thumbnailSliderSettings}
                  >
                    {productDetail.images &&
                      productDetail.images.map((image, index) => (
                        <div
                          key={index}
                          className={`item ${
                            index === mainSliderIndex ? "active" : ""
                          }`}
                          onClick={() => handleThumbnailClick(index)}
                        >
                          <img
                            style={{ height: "100px", width: "100px" }}
                            className="w-100"
                            src={getAssetUri("variations", image.image)}
                            alt={`Thumbnail ${index + 1}`}
                          />
                        </div>
                      ))}
                  </Slider>
                </div>
                <div className="w-full md:col-span-1">
                  <div className="product-container bg-white p-8 rounded-lg">
                    <h2 className="product-title text-heading font-semibold text-gray-800 mb-4">
                      {productDetail.title}
                    </h2>
                    <Rating
                      size="small"
                      name="read-only"
                      value={productDetail.rating}
                      className="text-left"
                      readOnly
                    />
                    <div className="price-section flex items-center justify-start gap-4">
                      <p className="current-price text-3xl font-bold text-[#333]">
                        {formatAmountToINR(productDetail.active_price.price)}
                      </p>
                      <p className="previous-price text-lg text-gray-500 line-through">
                        {formatAmountToINR(productDetail.active_price.mrp)}
                        <span className="text-sm text-gray-400">
                          Tax included
                        </span>
                      </p>
                    </div>

                    <p className="description text-base text-gray-700 my-6">
                      {productDetail.product.description}
                    </p>
                    <div className="feature-list mb-2">
                      {productDetail.product &&
                        productDetail.product.features &&
                        productDetail.product.features.map((item, index) => {
                          return (
                            <li key={index} className="text-base">
                              {item.feature}
                            </li>
                          );
                        })}
                    </div>
                    {productDetail.product.deliver_between > 0 && (
                      <div className="delivery-info flex items-center text-base text-gray-800 mb-2">
                        <FaTruck className="text-green-500 mr-2" />
                        <p>
                          {`Delivery within ${productDetail.product.deliver_between} business days`}
                        </p>
                      </div>
                    )}
                    {productDetail.product.days_of_return > 0 && (
                      <div className="return-policy flex items-center text-base text-gray-800 mb-5">
                        <FaShieldAlt className="text-red-500 mr-2" />
                        <p>
                          {`${productDetail.product.days_of_return} -day return
                          policy: We offer a ${productDetail.product.days_of_return} -day return policy for this product.`}
                        </p>
                      </div>
                    )}

                    {productDetail.active_price.offer &&
                      productDetail.active_price.offer == "50" && (
                        <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 my-5">
                          <p className="font-semibold">
                            Special Offer: Buy One, Get One Free!
                          </p>
                          <p>
                            For a limited time, buy one and get another one for
                            free. Hurry up!
                          </p>
                        </div>
                      )}
                    <div className="grid grid-cols-2 gap-4">
                      <Button
                        className="hover:bg-accent-500 w-full lg:mb-0 mb-2 mr-2 active:bg-accent-500 hover:text-white active:text-white"
                        onClick={handleAddToCart}
                        variant="outlined"
                        color="secondary"
                        endIcon={<FaCartPlus />}
                      >
                        Add to Cart
                      </Button>
                      <Link to={CHECKOUT_ROUTE}>
                        <Button
                          className="bg-accent-500 w-full text-white hover:bg-primary-500 active:bg-primary-500"
                          variant="contained"
                          endIcon={<FaShoppingCart />}
                        >
                          Checkout Now
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mx-auto max-w-[1380px] px-8 py-6 bg-white mb-5">
              <div className="customTabs">
                <ul className="list w-full mb-4 p-0 justify-center flex flex-wrap gap-5">
                  <li>
                    <Button
                      className={`${currentTab === 0 && "active"} pt-2 px-3`}
                      onClick={() => {
                        setCurrentTab(0);
                      }}
                    >
                      Description
                    </Button>
                  </li>
                  <li>
                    <Button
                      className={`${currentTab === 1 && "active"} pt-2 px-3`}
                      onClick={() => {
                        setCurrentTab(1);
                      }}
                    >
                      Additional info
                    </Button>
                  </li>
                  <li>
                    <Button
                      className={`${currentTab === 2 && "active"} pt-2 px-3`}
                      onClick={() => {
                        setCurrentTab(2);
                      }}
                    >
                      Reviews (
                      {reviewData && reviewData.data
                        ? reviewData.data.length
                        : 0}
                      )
                    </Button>
                  </li>
                </ul>
                {currentTab === 0 && (
                  <div className="tabContent">
                    <p className="description text-base text-gray-700 my-6">
                      {productDetail.product.description}
                    </p>
                    <div className="feature-list mb-2">
                      {productDetail.product &&
                        productDetail.product.features &&
                        productDetail.product.features.map((item, index) => {
                          return (
                            <li key={index} className="text-base">
                              {item.feature}
                            </li>
                          );
                        })}
                    </div>
                    {productDetail.product.deliver_between > 0 && (
                      <div className="delivery-info flex items-center text-base text-gray-800 mb-2">
                        <FaTruck className="text-green-500 mr-2" />
                        <p>
                          {`Delivery within ${productDetail.product.deliver_between}  business days`}
                        </p>
                      </div>
                    )}
                    {productDetail.product.days_of_return > 0 && (
                      <div className="return-policy flex items-center text-base text-gray-800 mb-5">
                        <FaShieldAlt className="text-red-500 mr-2" />
                        <p>
                          {`${productDetail.product.days_of_return} -day return
                          policy: We offer a ${productDetail.product.days_of_return} -day return policy for this product.`}
                        </p>
                      </div>
                    )}
                  </div>
                )}

                {currentTab === 1 && (
                  <div className="tabContent">
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody>
                          {productDetail.values.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell className="font-semibold">
                                {item.value.attribute.attribute_name}
                              </TableCell>
                              <TableCell>
                                {item.value.attribute_value}
                              </TableCell>
                            </TableRow>
                          ))}
                          {productDetail.product.informations.map(
                            (item, index) => (
                              <TableRow key={index}>
                                <TableCell className="font-semibold">
                                  {item.attribute}
                                </TableCell>
                                <TableCell>{item.value}</TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}

                {currentTab === 2 && (
                  <div className="tabContent">
                    <div>
                      <div className="font-[sans-serif] bg-white">
                        <h3 className="text-lg font-bold text-[#333]">
                          Reviews(
                          {reviewData && reviewData.data
                            ? reviewData.data.length
                            : 0}
                          )
                        </h3>
                        <div className="grid md:grid-cols-2 gap-12 mt-6">
                          <div>
                            {reviewData && (
                              <div className="space-y-3">
                                <div className="flex items-center">
                                  <p className="text-sm text-[#333] font-bold">
                                    5.0
                                  </p>
                                  <svg
                                    className="w-5 fill-[#333] ml-1"
                                    viewBox="0 0 14 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M7 0L9.4687 3.60213L13.6574 4.83688L10.9944 8.29787L11.1145 12.6631L7 11.2L2.8855 12.6631L3.00556 8.29787L0.342604 4.83688L4.5313 3.60213L7 0Z" />
                                  </svg>
                                  <div className="bg-gray-400 rounded w-full h-2 ml-3">
                                    <div
                                      style={{
                                        width:
                                          parseFloat(
                                            reviewData.five_star_percentage || 0
                                          ).toFixed(2) + "%",
                                      }}
                                      className={`h-full rounded bg-[#333]`}
                                    />
                                  </div>
                                  <p className="text-sm text-[#333] font-bold ml-3">
                                    {parseInt(
                                      reviewData.five_star_percentage || 0
                                    )}
                                    %
                                  </p>
                                </div>
                                <div className="flex items-center">
                                  <p className="text-sm text-[#333] font-bold">
                                    4.0
                                  </p>
                                  <svg
                                    className="w-5 fill-[#333] ml-1"
                                    viewBox="0 0 14 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M7 0L9.4687 3.60213L13.6574 4.83688L10.9944 8.29787L11.1145 12.6631L7 11.2L2.8855 12.6631L3.00556 8.29787L0.342604 4.83688L4.5313 3.60213L7 0Z" />
                                  </svg>
                                  <div className="bg-gray-400 rounded w-full h-2 ml-3">
                                    <div
                                      style={{
                                        width:
                                          parseFloat(
                                            reviewData.four_star_percentage || 0
                                          ).toFixed(2) + "%",
                                      }}
                                      className={`h-full rounded bg-[#333]`}
                                    />
                                  </div>
                                  <p className="text-sm text-[#333] font-bold ml-3">
                                    {parseInt(
                                      reviewData.four_star_percentage || 0
                                    )}
                                    %
                                  </p>
                                </div>
                                <div className="flex items-center">
                                  <p className="text-sm text-[#333] font-bold">
                                    3.0
                                  </p>
                                  <svg
                                    className="w-5 fill-[#333] ml-1"
                                    viewBox="0 0 14 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M7 0L9.4687 3.60213L13.6574 4.83688L10.9944 8.29787L11.1145 12.6631L7 11.2L2.8855 12.6631L3.00556 8.29787L0.342604 4.83688L4.5313 3.60213L7 0Z" />
                                  </svg>
                                  <div className="bg-gray-400 rounded w-full h-2 ml-3">
                                    <div
                                      style={{
                                        width:
                                          parseFloat(
                                            reviewData.three_star_percentage ||
                                              0
                                          ).toFixed(2) + "%",
                                      }}
                                      className={`h-full rounded bg-[#333]`}
                                    />
                                  </div>
                                  <p className="text-sm text-[#333] font-bold ml-3">
                                    {parseInt(
                                      reviewData.three_star_percentage || 0
                                    )}
                                    %
                                  </p>
                                </div>
                                <div className="flex items-center">
                                  <p className="text-sm text-[#333] font-bold">
                                    2.0
                                  </p>
                                  <svg
                                    className="w-5 fill-[#333] ml-1"
                                    viewBox="0 0 14 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M7 0L9.4687 3.60213L13.6574 4.83688L10.9944 8.29787L11.1145 12.6631L7 11.2L2.8855 12.6631L3.00556 8.29787L0.342604 4.83688L4.5313 3.60213L7 0Z" />
                                  </svg>
                                  <div className="bg-gray-400 rounded w-full h-2 ml-3">
                                    <div
                                      style={{
                                        width:
                                          parseFloat(
                                            reviewData.two_star_percentage || 0
                                          ).toFixed(2) + "%",
                                      }}
                                      className={`h-full rounded bg-[#333]`}
                                    />
                                  </div>
                                  <p className="text-sm text-[#333] font-bold ml-3">
                                    {parseInt(
                                      reviewData.two_star_percentage || 0
                                    )}
                                    %
                                  </p>
                                </div>
                                <div className="flex items-center">
                                  <p className="text-sm text-[#333] font-bold">
                                    1.0
                                  </p>
                                  <svg
                                    className="w-5 fill-[#333] ml-1"
                                    viewBox="0 0 14 13"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M7 0L9.4687 3.60213L13.6574 4.83688L10.9944 8.29787L11.1145 12.6631L7 11.2L2.8855 12.6631L3.00556 8.29787L0.342604 4.83688L4.5313 3.60213L7 0Z" />
                                  </svg>
                                  <div className="bg-gray-400 rounded w-full h-2 ml-3">
                                    <div
                                      style={{
                                        width:
                                          parseFloat(
                                            reviewData.one_star_percentage || 0
                                          ).toFixed(2) + "%",
                                      }}
                                      className={`h-full rounded bg-[#333]`}
                                    />
                                  </div>
                                  <p className="text-sm text-[#333] font-bold ml-3">
                                    {parseInt(
                                      reviewData.one_star_percentage || 0
                                    )}
                                    %
                                  </p>
                                </div>
                              </div>
                            )}
                            <InfiniteScroll
                              className="max-h-[300px] overflow-y-scroll reviewScroller"
                              dataLength={reviewData.data.length}
                              next={fetchMoreReview}
                              hasMore={
                                reviewData.total !== reviewData.data.length
                              }
                              loader={<Loader className="pb-5" />}
                            >
                              {reviewData &&
                                reviewData.data &&
                                reviewData.data.map((item, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="mt-3 bg-white md:shadow  p-3"
                                    >
                                      <div className="flex items-start">
                                        <img
                                          src="https://readymadeui.com/team-2.webp"
                                          className="w-12 h-12 rounded-full border-2 border-white"
                                        />
                                        <div className="ml-3">
                                          <h4 className="text-sm font-bold text-[#333]">
                                            {item.user.name}
                                          </h4>
                                          <div className="flex space-x-1 mt-1">
                                            <Rating
                                              size="small"
                                              name="read-only"
                                              value={item.rating}
                                              className="text-left"
                                              readOnly
                                            />
                                            <p className="text-xs !ml-2 font-semibold text-[#333]">
                                              {timeAgo(item.created_at)}
                                            </p>
                                          </div>
                                          <b className="text-xs mt-1">
                                            {item.title}
                                          </b>
                                          <p className="text-sm mt-1 text-[#333]">
                                            {item.review}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </InfiniteScroll>
                          </div>

                          <div className="w-full ">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                              Write a Review
                            </h1>
                            <form
                              onSubmit={formik.handleSubmit}
                              className="space-y-4 md:space-y-6"
                            >
                              <Input
                                type={"text"}
                                name={"title"}
                                placeholder={"Review title"}
                                value={formik.values.title}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.title}
                                serverError={validationError.title}
                                touched={formik.touched.title}
                              />
                              <Rating
                                size="large"
                                name="rating"
                                value={formik.values.rating}
                                onChange={handleRatingChange}
                                className="text-left"
                              />
                              <TextArea
                                name={"review"}
                                rows={4}
                                placeholder={"Enter review"}
                                value={formik.values.review}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.errors.review}
                                serverError={validationError.review}
                                touched={formik.touched.review}
                              />
                              <button
                                type="submit"
                                className="w-full text-white bg-primary-500 hover:bg-accent-500 focus:ring-4 focus:outline-none focus:ring-accent-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                              >
                                Submit
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ProductDetail;
